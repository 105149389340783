/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { Card, CardContent, Grid, Paper, Tooltip, Typography, Select, MenuItem, DialogContent, DialogActions, Dialog, Button, DialogTitle, TextField } from '@mui/material';
import customStyles from './EventSummary.module.scss';

import { isEmpty } from './../../../helper-classes/utility-functions';
import GetSvgIcon from '../../../util/svgImage_util';
import EventSummaryCard from './EventSummaryCard';
import { getConvertedEndDate, getConvertedStartDate } from '../../../util/trip_util';
import { EVENT_TYPES_CATEGORY } from './constants';
import EventSetting from './EventSetting';
import { CARD_FILTER_CHANGE, DATE_FILTER_CHANGE, DATE_RANGE, EVENT_SETTINGS, GET_ALL_EVENTS } from '../../../containers/DashCam/constants.dashcam';

export type Props = {
      dashcam: Object,
      showNotification: Function,
      updateLoader: Function,
      getFilterEvent: Function,
      isComparisonVisible: boolean,
      currentEventsCount: any,
      historyEventsCount: any,
      comparisionText: string,
      setFilterEventObj: Function,
      filterEventObj: Object,
      trackAnalytics: Function,
};

type State = {
    showSetting: boolean,
    filterObj: Object,
    dateRangeDialogOpen: boolean,
    isAllDashcam: boolean,
}

class EventSummary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { filterObj } = this.props.filterEventObj;
        this.state = {
            showSetting: false,
            filterObj: !isEmpty(filterObj) ? filterObj : {},
            dateRangeDialogOpen: false,
            isAllDashcam: this.props.filterEventObj.isAllDashcam || false,
            endDateError: '',
            startDateError: '',
        };
    }

    componentDidMount() {
        const { dateRange } = this.props.filterEventObj;
        this.handleDateChange({ target: { value: dateRange }, initialRender: true });
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.filterEventObj &&
            nextProps.filterEventObj &&
            this.props.filterEventObj.filterObj &&
            nextProps.filterEventObj.filterObj &&
            this.props.filterEventObj.filterObj.eventKey !==
            nextProps.filterEventObj.filterObj.eventKey) {
            this.setState({ filterObj: nextProps.filterEventObj.filterObj });
        }
    }

    handleDateChange = (e: any) => {
        const {
            filterEventObj,
            setFilterEventObj,
            trackAnalytics,
        } = this.props;
        let { startDate, endDate } = filterEventObj;
        const { dateFrom, dateTo } = filterEventObj;
        switch (e.target.value) {
        case 'TODAY':
            startDate = getConvertedStartDate(Date.now());
            endDate = getConvertedEndDate(Date.now());
            break;
        case 'YESTERDAY':
            startDate = getConvertedStartDate(moment().subtract(1, 'days'));
            endDate = getConvertedEndDate(moment().subtract(1, 'days'));
            break;
        case 'LAST_7_DAYS':
            startDate = getConvertedStartDate(moment().subtract(7, 'days'));
            endDate = getConvertedEndDate(Date.now());
            break;
        case 'LAST_30_DAYS':
            startDate = getConvertedStartDate(moment().subtract(30, 'days'));
            endDate = getConvertedEndDate(Date.now());
            break;
        case 'CUSTOM':
            if (!e.initialRender) {
                this.openDateRangeDialog();
                return;
            }
            startDate = getConvertedStartDate(dateFrom);
            endDate = getConvertedEndDate(dateTo);
            break;
        default:
            startDate = getConvertedStartDate(Date.now());
            endDate = getConvertedEndDate(Date.now());
            break;
        }
        const eventParams = {
            ...filterEventObj,
            dateRange: e.target.value,
            startDate,
            endDate,
            filterObj: this.state.filterObj,
            isAllDashcam: this.state.isAllDashcam,
        };
        if (!e.initialRender) trackAnalytics(DATE_FILTER_CHANGE, e.target.value);
        setFilterEventObj(eventParams);
    };

    openDateRangeDialog = () => {
        this.setState({ dateRangeDialogOpen: true });
    };

    handleFilter = (filterObj: Object) => {
        let selectedFilterEventObj = {};
        if (filterObj.eventKey && this.state.filterObj.eventKey
            && this.state.filterObj.eventKey === filterObj.eventKey) {
            selectedFilterEventObj = {};
            this.props.getFilterEvent({});
            this.setState({ filterObj: {} });
        } else {
            selectedFilterEventObj = filterObj;
        }
        this.props.getFilterEvent(selectedFilterEventObj);
        this.setState({ filterObj: selectedFilterEventObj }, () => {
            if (!isEmpty(this.state.filterObj)) {
                const { eventKey } = this.state.filterObj;
                this.props.trackAnalytics(CARD_FILTER_CHANGE, DATE_RANGE, eventKey);
            }
        });
    }

    getSelectedEvent = (eventKey: string) => this.state.filterObj.eventKey === eventKey;

    getEventSummaryCardContent = (
        type:string,
        eventKey: string,
        currentEventsCount: any,
        historyEventsCount: any,
        isComparisonVisible: boolean,
    ) => (
        <EventSummaryCard
            currentEventsCount={currentEventsCount}
            historyEventsCount={historyEventsCount}
            eventKey={eventKey}
            eventType={type}
            applyFilter={(filterObj: Object) => this.handleFilter(filterObj)}
            isSelected={this.getSelectedEvent(eventKey)}
            isComparisonVisible={isComparisonVisible}
        />)

    showLoader = (showLoader: boolean) => this.props.updateLoader(showLoader);

    showEvent = () => {
        this.setState({ showSetting: false });
    }

    getEventSummaryCount = (events: any, eventName: string) => {
        if (events[eventName]) {
            return events[eventName].total;
        }
        return 0;
    }

    getTableHeader = (eventText: string, currentEventsCount: number, historyEventCount: number) => {
        let headerClass = customStyles.total_count;
        if (eventText === 'safety' || eventText === 'monitoring') headerClass = customStyles[`${eventText}_total_count`];
        let countDelta = currentEventsCount - historyEventCount;
        const isCurrentEventUp = countDelta > 0;
        const isEventEqual = countDelta === 0;
        countDelta = Math.abs(countDelta);

        return (
            <div className={customStyles.center}>
                <div className={customStyles.total_text}>
                    {eventText}
                </div>
                <div className={headerClass}>
                    <Tooltip title={currentEventsCount || 0} disableFocusListener>
                        <span>{currentEventsCount || 0}</span>
                    </Tooltip>
                </div>
                { this.props.isComparisonVisible &&
                    <div className={customStyles.compare_count_content}>
                        { isEventEqual &&
                            <div className={customStyles.equal}>
                                <span>- -</span>
                            </div>
                        }
                        { !isEventEqual &&
                            <div className={customStyles.count}>
                                <Tooltip title={countDelta || 0} disableFocusListener>
                                    <span>{countDelta || 0}</span>
                                </Tooltip>
                                { isCurrentEventUp ?
                                    <ArrowDropUp viewBox="3 -2 20 20" style={{ fontSize: 18, color: '#e02020' }} /> :
                                    <ArrowDropDown viewBox="3 -2 20 20" style={{ fontSize: 18, color: '#6dd400' }} />
                                }
                            </div>
                        }
                        <div className={customStyles.text}>
                            {this.props.comparisionText}
                        </div>
                    </div>
                }
            </div>
        );
    }

    handleDateRangeDialogClose = () => {
        this.setState({ startDateError: '', endDateError: '' });
        const { prevDateFrom, prevDateTo } = this.props.filterEventObj;
        const filterEventObj = {
            ...this.props.filterEventObj,
            dateFrom: prevDateFrom,
            dateTo: prevDateTo,
        };
        this.props.setFilterEventObj(filterEventObj);
        this.setState({ dateRangeDialogOpen: false });
    };

    handleDateRangeDateFromChange = (date: Date, maxDate: Date, minDate: Date) => {
        const { dateFrom } = this.props.filterEventObj;
        const filterEventObj = {
            ...this.props.filterEventObj,
            prevDateFrom: dateFrom,
            dateFrom: date,
        };
        if (!date) {
            this.setState({ startDateError: '' });
        } else if (!moment(date).isValid()) {
            this.setState({ startDateError: 'invalidDate' });
        } else if ((moment(date).isAfter(moment(maxDate).endOf('day')))) {
            this.setState({ startDateError: 'disableFuture' });
        } else if (moment(date).isBefore(minDate, 'd')) {
            this.setState({ startDateError: 'minDate' });
        } else {
            this.setState({ startDateError: '' });
            this.props.setFilterEventObj(filterEventObj);
        }
    };


    handleDateRangeDateToChange = (date: Date, maxDate: Date, minDate: Date) => {
        const { dateTo } = this.props.filterEventObj;
        const filterEventObj = {
            ...this.props.filterEventObj,
            prevDateTo: dateTo,
            dateTo: date,
        };
        if (!date) {
            this.setState({ endDateError: '' });
        } else if (!moment(date).isValid()) {
            this.setState({ endDateError: 'invalidDate' });
        } else if (moment(date).isBefore(minDate, 'd')) {
            this.setState({ endDateError: 'minDate' });
        } else if (moment(date).isAfter(moment(maxDate).endOf('day'))) {
            this.setState({ endDateError: 'disableFuture' });
        } else {
            this.setState({ endDateError: '' });
            this.props.setFilterEventObj(filterEventObj);
        }
    };

    handleDateRangeChange = () => {
        const { filterObj } = this.state;
        const { dateFrom, dateTo } = this.props.filterEventObj;
        if (dateFrom.isAfter(dateTo, 'date')) {
            return;
        }

        this.setState({
            dateRangeDialogOpen: false,
        }, () => {
            const filterEventObj = {
                ...this.props.filterEventObj,
                prevDateFrom: dateFrom,
                prevDateTo: dateTo,
                startDate: getConvertedStartDate(dateFrom),
                endDate: getConvertedEndDate(dateTo),
                filterObj,
                dateRange: 'CUSTOM',
                isAllDashcam: this.state.isAllDashcam,
            };
            this.props.setFilterEventObj(filterEventObj);
            this.props.trackAnalytics(DATE_FILTER_CHANGE, filterEventObj.dateRange);
        });
    };

    getAllEvents = () => {
        const { isAllDashcam } = this.state;
        this.setState({ filterObj: {} }, () => {
            const data = {
                ...this.props.filterEventObj,
                filterObj: this.state.filterObj,
                isAllDashcam,
                updateSelectedView: true,
            };
            this.props.setFilterEventObj(data);
        });
    }

    getEndDateErrorMessage = () => {
        switch (this.state.endDateError) {
        case 'disableFuture': {
            return 'End Date should not be in future';
        }
        case 'minDate': {
            return 'End Date should be after Start Date';
        }
        case 'invalidDate': {
            return 'Invalid Date format';
        }
        default: {
            return '';
        }
        }
    }

    getStartDateErrorMessage = () => {
        switch (this.state.startDateError) {
        case 'disableFuture': {
            return 'Start Date should be before End Date';
        }
        case 'minDate': {
            return 'Start Date should not be before 30 days';
        }
        case 'invalidDate': {
            return 'Invalid Date format';
        }
        default: {
            return '';
        }
        }
    }

    render() {
        const {
            dashcam,
            isComparisonVisible,
            currentEventsCount,
            historyEventsCount,
            filterEventObj,
        } = this.props;
        const {
            dateRangeDialogOpen,
            isAllDashcam,
        } = this.state;
        const { dateFrom, dateTo, dateRange } = filterEventObj;

        const dateFromMaxDate = moment();
        let dateToMinDate = moment().subtract(30, 'days');
        if (dateFrom) {
            dateToMinDate = dateFrom;
        }
        const dashcamName = isAllDashcam ? 'All Dashcams' : dashcam.name;
        return (
            <React.Fragment>
                {this.state.showSetting ?
                    <EventSetting
                        imei={dashcam.imei}
                        dashcamName={dashcam.name}
                        status={dashcam.status || ''}
                        showNotification={this.props.showNotification}
                        showEvent={this.showEvent}
                        showLoader={this.showLoader}
                    /> :
                    <div className={customStyles.container}>
                        <Paper className={customStyles.paper}>
                            <Grid container justifyContent="flex-start" alignItems="center" className={customStyles.title}>
                                <Grid item xs={6} >
                                    <Tooltip title={dashcamName} disableFocusListener>
                                        <Typography variant="h6" className={customStyles.title_text}>
                                            {dashcamName}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6} >
                                    {!isAllDashcam &&
                                    <Tooltip title="Event Settings" disableFocusListener>
                                        <span>
                                            <GetSvgIcon
                                                type="dashcamEventSettings"
                                                className={customStyles.settings_icon}
                                                onClick={() => {
                                                    this.setState({ showSetting: true });
                                                    this.props.trackAnalytics(EVENT_SETTINGS);
                                                }}
                                            />
                                        </span>
                                    </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={8} >
                                    <Card className={customStyles.event_count_card}>
                                        <CardContent className={customStyles.event_card_content}>
                                            {this.getTableHeader('Total Events', currentEventsCount.total, historyEventsCount.total)}
                                            <div className={customStyles.center}>
                                                <div className={customStyles.total_text}>
                                                    <Select
                                                        sx={{ '.MuiSelect-select': { paddingRight: '32px !Important' } }}
                                                        disableUnderline
                                                        className={customStyles.date_picker}
                                                        value={dateRange}
                                                        onChange={this.handleDateChange}
                                                        name="dateRange"
                                                    >
                                                        <MenuItem value="TODAY">Today</MenuItem>
                                                        <MenuItem value="YESTERDAY">Yesterday</MenuItem>
                                                        <MenuItem value="LAST_7_DAYS">Last 7 Days</MenuItem>
                                                        <MenuItem value="LAST_30_DAYS">Last 30 Days</MenuItem>
                                                        <MenuItem
                                                            value="CUSTOM"
                                                            onClick={() => {
                                                                if (dateRange === 'CUSTOM') {
                                                                    this.handleDateChange({ target: { value: 'CUSTOM' }, initialRender: false });
                                                                }
                                                            }}
                                                        >
                                                            {dateRange !== 'CUSTOM' ? 'Date Range' : `${dateFrom.format('MM/DD/YY')} - ${dateTo.format('MM/DD/YY')}`}
                                                        </MenuItem>
                                                    </Select>
                                                </div>
                                                <div
                                                    className={customStyles.view_all}
                                                    onClick={() => {
                                                        this.getAllEvents();
                                                        this.props.trackAnalytics(GET_ALL_EVENTS);
                                                    }}
                                                    onKeyDown={() => {}}
                                                    role="button"
                                                    tabIndex={0}
                                                >
                                                    View all
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={4} />
                                {EVENT_TYPES_CATEGORY.map((e: Object) => {
                                    const eventName = e.name.toLowerCase();
                                    const currentCount = this.getEventSummaryCount(
                                        currentEventsCount,
                                        eventName,
                                    );
                                    const historyCount = this.getEventSummaryCount(
                                        historyEventsCount,
                                        eventName,
                                    );
                                    return (
                                        <React.Fragment key={eventName}>
                                            <Grid item xs={8} className={customStyles[`${eventName}`]}>
                                                <Card className={customStyles.event_count_card}>
                                                    <Grid container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            className={
                                                                customStyles.event_card_header
                                                            }
                                                        >
                                                            <CardContent
                                                                className={
                                                                    customStyles.event_card_content
                                                                }
                                                            >
                                                                {this.getTableHeader(
                                                                    eventName,
                                                                    currentCount,
                                                                    historyCount,
                                                                )}
                                                            </CardContent>
                                                        </Grid>
                                                        {e.events.map((d: string) => {
                                                            if (d === 'sharpTurnRight') return '';
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    className={
                                                                        customStyles[`event_safety_card_${eventName}`]
                                                                    }
                                                                    key={d}
                                                                >
                                                                    {this
                                                                        .getEventSummaryCardContent(
                                                                            eventName,
                                                                            d,
                                                                            currentEventsCount,
                                                                            historyEventsCount,
                                                                            isComparisonVisible,
                                                                        )
                                                                    }
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                            <Grid item xs={4} />
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                            <br />
                        </Paper>
                    </div>}
                <Dialog open={dateRangeDialogOpen} onClose={this.handleDateRangeDialogClose} aria-labelledby="form-dialog-title" maxWidth="xs">
                    <DialogTitle>Select Date Range</DialogTitle>
                    <DialogContent>
                        <Grid container justifyContent="flex-start" alignItems="flex-start">
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <DatePicker
                                        label="Start Date"
                                        onChange={(date: Date) =>
                                            this.handleDateRangeDateFromChange(
                                                date,
                                                dateFromMaxDate,
                                                moment().subtract(30, 'days'),
                                            )}
                                        renderInput={params =>
                                            <TextField
                                                {...params}
                                                helperText={this.getStartDateErrorMessage()}
                                                error={!!this.state.startDateError}
                                            />
                                        }
                                        closeOnSelect
                                        PopperProps={{
                                            placement: 'bottom-end',
                                            sx: { '.MuiPickersToolbar-penIconButton': { display: 'none' } },
                                        }}
                                        showToolbar
                                        toolbarTitle=""
                                        format="MM/DD/YYYY"
                                        disableFuture
                                        value={dateFrom}
                                        mask="__/__/____"
                                        variant="inline"
                                        onError={(startDateError) => {
                                            this.setState({ startDateError });
                                        }}
                                        minDate={moment().subtract(30, 'days')}
                                        maxDate={dateFromMaxDate}
                                        onOpen={() => {
                                            if (this.state.startDateError) {
                                                this.handleDateRangeDateFromChange(
                                                    moment(),
                                                    dateFromMaxDate,
                                                    moment().subtract(30, 'days'),
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <DatePicker
                                        label="End Date"
                                        onChange={(date: Date) =>
                                            this.handleDateRangeDateToChange(
                                                date,
                                                dateFromMaxDate,
                                                dateToMinDate,
                                            )}
                                        renderInput={params =>
                                            <TextField
                                                {...params}
                                                helperText={this.getEndDateErrorMessage()}
                                                error={!!this.state.endDateError}
                                            />
                                        }
                                        closeOnSelect
                                        PopperProps={{
                                            placement: 'bottom-end',
                                            sx: { '.MuiPickersToolbar-penIconButton': { display: 'none' } },
                                        }}
                                        showToolbar
                                        toolbarTitle=""
                                        format="MM/DD/YYYY"
                                        disableFuture
                                        value={dateTo}
                                        mask="__/__/____"
                                        variant="inline"
                                        onError={(endDateError) => {
                                            this.setState({ endDateError });
                                        }}
                                        minDate={dateToMinDate}
                                        maxDate={moment()}
                                        onOpen={() => {
                                            if (this.state.endDateError) {
                                                this.handleDateRangeDateToChange(
                                                    moment(),
                                                    dateFromMaxDate,
                                                    dateToMinDate,
                                                );
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDateRangeDialogClose} color="primary">
                                Cancel
                        </Button>
                        <Button
                            onClick={this.handleDateRangeChange}
                            color="primary"
                            variant="contained"
                            disabled={this.state.endDateError || this.state.startDateError}
                        >
                                OK
                        </Button>
                    </DialogActions>
                </Dialog>

            </React.Fragment>
        );
    }
}

export default EventSummary;

