/* @flow */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { Card, List } from '@mui/material';
import AppLoader from '../../AppLoader';

import { NO_RESULTS_FOUND, PLEASE_ENTER_AT_LEAST_THREE_CHARACTERS } from '../List/constants.list';

type Props = {
    data: Object,
    pagination: {
        isPagination: boolean,
        pageChanged: Function,
    },
    dense:? boolean,
    classes: Object,
    height: number,
    isLoader:? boolean,
    currentPage: number,
    isSearch: boolean,
    getListItem: Function,
    totalNumberofPage: number,
    showMessage: boolean,
    id: string,
    cardListStyle:? string,
}

const styles = () => ({
    card: {
        backgroundColor: '#999b9e',
    },
    items: {
        overflow: 'auto',
        height: 'calc(100vh - 115px)',
    },
    noPadding: {
        'padding-top': '0px',
        'min-height': 700,
    },
    listPadding: {
        'padding-top': '4px',
    },
    alignCenter: {
        'text-align': 'center',
        display: 'inherit',
    },
});

export type State = {
    hideLoader: boolean
};

class DrawerList extends Component<Props, State> {
    static defaultProps = {
        showMessage: true,
        id: 'rightdrawer-list',
    };
    constructor(props) {
        super(props);
        this.state = {
            hideLoader: true,
        };
    }

    componentDidMount = () => {
        /**
         * Code block handling the scroll event and calling the paginate function
         * once the scroller reaches the end.
         */
        const {
            id,
        } = this.props;

        const listArea = document.getElementById(id);
        if (listArea) {
            listArea.onscroll = () => {
                if (listArea.scrollTop + listArea.clientHeight + listArea.offsetTop
                    >= listArea.scrollHeight) {
                    const page = this.props.currentPage + 1;
                    this.pageChanged(page);
                }
            };
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.currentPage !== nextProps.currentPage
            && !this.state.hideLoader) {
            this.setState({ hideLoader: true });
        }
    }

    pushLoader = (item, isLoader) => {
        const {
            pagination,
        } = this.props;
        const hideLoader = (isLoader === undefined) ? this.state.hideLoader : isLoader;

        if (pagination && pagination.isPagination) {
            const loader = (<AppLoader
                key="app-loader"
                loaderStyle={{
                    'padding-top': '10px',
                    'padding-bottom': '10px',
                    'text-align': 'center',
                    display: `${hideLoader ? 'none' : 'block'}`,
                }}
            />);
            item.push(loader);
        }
        return item;
    };

    pageChanged = (page) => {
        const {
            pagination,
        } = this.props;

        if (page < this.props.totalNumberofPage && (pagination && pagination.isPagination)) {
            this.setState({
                hideLoader: false,
            }, () => this.props.pagination.pageChanged(page, this.props.isSearch));
        }
    }

    listItems() {
        const { classes } = this.props;
        const {
            data,
            currentPage,
            totalNumberofPage,
            showMessage,
        } = this.props;
        let item = [];
        if (data) {
            if (data.length <= 0 && showMessage) {
                if (this.props.isSearch) {
                    item = [
                        <span
                            key={PLEASE_ENTER_AT_LEAST_THREE_CHARACTERS}
                            className={classes.alignCenter}
                        >{PLEASE_ENTER_AT_LEAST_THREE_CHARACTERS}
                        </span>];
                } else {
                    item = [<span key="noData" className={classes.alignCenter}>{NO_RESULTS_FOUND}</span>];
                }
                return item;
            }
            item = data.map(d => this.props.getListItem(d));
            if (currentPage < totalNumberofPage - 1) {
                this.pushLoader(item);
            }
        }
        return item;
    }

    getList() {
        const {
            pagination,
            dense,
            classes,
            cardListStyle = '',
        } = this.props;

        return (pagination && pagination.isPagination) ?
            (
                <List key={Math.random()} component="div" className={classes.listPadding}>
                    {this.listItems()}
                </List>
            ) :
            (
                <Card className={!cardListStyle ? classes.noPadding : cardListStyle} dense={dense}>
                    {this.listItems()}
                </Card>
            );
    }

    list() {
        const {
            isLoader,
        } = this.props;
        return isLoader ? this.pushLoader([], !isLoader) : this.getList();
    }

    render() {
        const {
            classes,
            height,
            id,
        } = this.props;
        let style = {};
        if (height) {
            style = { height: `calc(100vh - 115px - ${height}px)` };
        }
        return (
            <div id={id} style={style} className={classes.items}>
                {this.list()}
            </div>
        );
    }
}

export default withStyles(styles)(DrawerList);
