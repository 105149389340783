/* @flow */
import { combineEpics } from 'redux-observable';
import { FETCH_DRIVER_SCORECARD_TRIPS } from './constants.scoreCardTrips';
import config from '../../../constants/Config';
import * as actions from './actions.scoreCardTrips';
import { getIframeUserToken } from '../../../helper-classes/utility-functions';

const querystring = require('querystring');

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    of: Function
}

export const getDriverTripsApiUrl = (action: Object) => {
    const { payload } = action;
    let url = config.get('DRIVER_SCORECARD_SERVICES_URL');
    let urlConfig = {};
    switch (action.type) {
    case FETCH_DRIVER_SCORECARD_TRIPS: {
        urlConfig = {
            name: payload.filters.name,
            startDate: payload.filters.startDate,
            endDate: payload.filters.endDate,
            offset: payload.pageNumber * payload.pageSize,
            limit: payload.pageSize,
            sort: `${payload.orderBy}:${payload.order}`,
            search: payload.filters.searchParam || '',
        };

        if (urlConfig.search === '') {
            delete urlConfig.search;
        }

        if (payload.filters.driverId === undefined) {
            url = `${url}/scorecard/assets/${payload.filters.vehicleId}/trips?`;
        } else {
            url = `${url}/scorecard/assets/${payload.filters.vehicleId}/drivers/${payload.filters.driverId}/trips?`;
        }
        return `${url}${querystring.stringify(urlConfig)}`;
    }
    default:
        return '';
    }
};

export const fetchDriverScorecardTrips =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_DRIVER_SCORECARD_TRIPS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(getDriverTripsApiUrl(action), headers);
            })
            .map((result) => {
                if (result.content.length > 0) {
                    return actions.fetchDriverScorecardTripsSuccess(result);
                }
                return actions.fetchDriverScorecardTripsError({ error: 'No driver scorecard trips data found for given params.' });
            })
            .catch(error => of(actions.fetchDriverScorecardTripsError(error)));

export default combineEpics(fetchDriverScorecardTrips);
