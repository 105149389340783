/* @flow */
/* eslint radix: ["error","as-needed"] */

import { combineEpics } from 'redux-observable';
import { FETCH_USERS_LIST } from './constants.userList';
import { fetchUsersListError, fetchUsersListSuccess } from './actions.userList';
import { getAuthorizationHeader, getGrailsAppBasedURL } from '../../helper-classes/utility-functions';
import config from '../../constants/Config';

type ObservarblesTypes = {
    getJSON: Function,
    of: Function
}

const formatData = (data: any) => {
    if (!data && data.length <= 0) return [];
    return data.map((d) => {
        const users = Object.assign({}, d);
        return users;
    });
};

function removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) =>
        arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);
}

const formatSortingString = (orderBy: string, order: string) => {
    let finalOrderBy = `user.${orderBy}`;
    if (orderBy === 'fullName') {
        finalOrderBy = 'user.firstName';
    } else if (orderBy === 'rolesDisplay') {
        finalOrderBy = 'roles.name';
    } else if (orderBy === 'comments') {
        finalOrderBy = 'comments';
    }
    const sorts = order && orderBy ? escape(`[{"property":"${finalOrderBy}","direction":"${order}"}]`) : escape('[{"property":"user.username","direction":"ASC"}]');
    return sorts;
};

const formatFilterString = (filter: Object) => {
    let searchString = {};
    if (filter && filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        searchString = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    }
    const filterParam = searchString.length > 0 ? escape(`[{"value":"${searchString[0]}","type":"String","properties":["firstName","lastName","username","email","phone","tz","units"],"comparison":"ilike","association":"user"},{"value":"${searchString[0]}","type":"String","property":"roles.name","comparison":"ilike"},{"value":"${searchString[0]}","type":"String","property":"securityUniverses.name","comparison":"ilike"},{"value":"${searchString[0]}","type":"String","property":"comments","comparison":"ilike"}]`) : null;
    return filterParam;
};

export const getURL = () => `${getGrailsAppBasedURL()}/export/user`;

export const getRequestPayload = (payload: any) => {
    const {
        order, orderBy, pageNumber, pageSize, filter = {},
    } = payload;
    const data = {
        sortParam: order && orderBy ? [[orderBy, order]] : undefined,
        start: pageNumber * pageSize,
        filterParam: [],
        searchParams: [''],
    };
    if (orderBy === 'statusStartDate') {
        data.sortParam = [[orderBy, order === 'desc' ? 'asc' : 'desc']];
    }
    if (filter.search && filter.search.length > 0 && typeof filter.search === 'string') {
        data.searchParams = [filter.search.replace(/[\s]{2,}/gi, ' ').trim()];
    } else delete data.searchParams;

    return data;
}

export const getUsers =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(FETCH_USERS_LIST)
            .distinctUntilChanged()
            .debounceTime(config.get('DEBOUNCE_TIME'))
            .mergeMap((action) => {
                const {
                    pageNumber,
                    pageSize,
                    order,
                    orderBy,
                    filter = {},
                } = action.payload;
                const offset = (parseInt(pageNumber) * parseInt(pageSize));
                const headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: getAuthorizationHeader(),
                };
                // Sorting Parameter creation
                const sorts = formatSortingString(orderBy, order);
                // Search Parameter creation
                const filterParam = formatFilterString(filter);
                let reqURL = `page=${pageNumber}&offset=${offset}&max=${pageSize}&sorts=${sorts}`;
                if (filterParam !== null) {
                    reqURL = `${reqURL}&filter=${filterParam}&filterOperator=or`;
                }
                return getJSON(`${getGrailsAppBasedURL()}/operation/json/localFleetAccountUserService/getNonDriverAccountUsers?${reqURL}`, headers)
                    .map((result) => {
                        const formatedData = formatData(result.data);
                        return fetchUsersListSuccess(removeDuplicates(formatedData, 'id'), result.total);
                    })
                    .catch(error => of(fetchUsersListError(error)));
            });

export default combineEpics(getUsers);
