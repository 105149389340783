/* @flow */
import React, { Component } from 'react';
import { Grid, Tooltip, Typography, IconButton } from '@mui/material';
import customStyles from './EventList.module.scss';
import List from '../../SharedComponents/List/List';
import AppLoader from '../../AppLoader';
import { isEmpty, convertDateTime, getUserSettingTimeZone, getTimeFormatAsperUserSettting, getFormattedTimezone } from '../../../helper-classes/utility-functions';
import EventCard from './EventCard';
import { EVENT_TYPE_MAPPING, WHITE_COLOR, GRAY_COLOR, DATE_FORMAT } from './constants';
import GetSvgIcon from '../../../util/svgImage_util';
import { getDashcamColorFromStatus, getCsvData } from '../../../util/dashcam_util';
import { BACK_TO_EVENT_DEFAULT_PAGE } from '../../../containers/DashCam/constants.dashcam';


export type Props = {
    handleBackButtonClick: Function,
    dashcam: Object,
    events: any,
    setEventVideoPlayerEvent: Function,
    isEventListError: boolean,
    isAllDashcam: boolean,
    selectedFilter: Object,
    getFilterEvent: Function,
    trackAnalytics: Function,
};

export type State = {
    paginatedEvents: any,
    eventResponse: Array<Object>,
    currentPage: number,
    totalNumberOfPage: number,
    isLoading: boolean,
    isError: boolean,
    isAllDashcam: boolean,
    selectedFilter: Object,
};
const rowsPerPage = 25;

class EventList extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            eventResponse: this.props.events,
            paginatedEvents: [],
            isLoading: false,
            isError: this.props.isEventListError,
            currentPage: 0,
            totalNumberOfPage: 0,
            isAllDashcam: this.props.isAllDashcam,
            selectedFilter: !isEmpty(this.props.selectedFilter) ? this.props.selectedFilter : {},
        };
    }

    componentDidMount() {
        this.setPaginatedEvents(this.props.events);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.events !== nextProps.events) {
            this.setPaginatedEvents(nextProps.events);
        }

        if (this.props.isAllDashcam !== nextProps.isAllDashcam) {
            this.setState({ isAllDashcam: nextProps.isAllDashcam });
        }

        if (nextProps.selectedFilter &&
            this.props.selectedFilter &&
            this.props.selectedFilter.eventKey !== nextProps.selectedFilter.eventKey) {
            this.setState({ selectedFilter: nextProps.selectedFilter });
        }
    }

    setPaginatedEvents = (events: Array<Object>) => {
        const totalNumberOfPage = Math.ceil(events.length / rowsPerPage);
        const paginatedEvents = events.slice(0, rowsPerPage);
        const currentPage = 0;
        this.setState({
            totalNumberOfPage, paginatedEvents, currentPage, eventResponse: events,
        });
    };

    loadNextPage = (page: number) => {
        const paginatedEvents =
        this.state.eventResponse.slice(0, (page * rowsPerPage) + rowsPerPage);
        const currentPage = page;
        this.setState({ paginatedEvents, currentPage });
    };

    getEventCardContent = (event: Object) => {
        if (!isEmpty(event)) {
            return (
                <EventCard
                    event={event}
                    key={event.id}
                    setEventVideoPlayerEvent={this.props.setEventVideoPlayerEvent}
                    isAllDashcam={this.state.isAllDashcam}
                    trackAnalytics={this.props.trackAnalytics}
                />
            );
        }
        return '';
    };

    handleBackButtonClick = (isFilterSelected: boolean) => {
        if (isFilterSelected) this.props.getFilterEvent({});
        else this.props.handleBackButtonClick();
        if (!isFilterSelected) this.props.trackAnalytics(BACK_TO_EVENT_DEFAULT_PAGE);
        this.props.setEventVideoPlayerEvent(null);
    };

    downloadEvent = (fileName: string) => {
        const { eventResponse } = this.state;
        let csvFileName = fileName;
        if (isEmpty(eventResponse)) return;
        const timeFormat = getTimeFormatAsperUserSettting();
        const data = eventResponse.map(d => ({
            date: convertDateTime(d.time, DATE_FORMAT),
            dashcamName: d.dashcamName,
            time: convertDateTime(d.time, (timeFormat === '12h') ? 'h:mm A' : 'H:mm'),
            eventType: EVENT_TYPE_MAPPING[d.eventType].name,
        }));
        const header = ['Date', 'CamCoach Name', `Time in [${getFormattedTimezone(getUserSettingTimeZone())}]`, 'Event Type'];
        if (csvFileName === 'All Events') csvFileName = 'All';
        csvFileName = (csvFileName || '').replace(/\s+/g, '_').toLowerCase();
        getCsvData(data, header, `${csvFileName}_events`);
    }

    render() {
        const { dashcam } = this.props;
        const {
            isAllDashcam, eventResponse, paginatedEvents, selectedFilter,
        } = this.state;
        const dashcamStatusColor = getDashcamColorFromStatus(dashcam);
        let dashcamName = isAllDashcam ? 'All Events' : dashcam.name;
        const isFilterSelected = isAllDashcam && selectedFilter.eventKey;

        if (isFilterSelected) {
            dashcamName = EVENT_TYPE_MAPPING[selectedFilter.eventKey].name;
        }

        return (
            <React.Fragment>
                <div className={customStyles.container}>
                    <Grid container justifyContent="flex-start" alignItems={isAllDashcam ? 'flex-start' : 'center'} className={customStyles.event_list_header_card}>
                        <Grid item xs={10}>
                            <Grid container justifyContent="flex-start" alignItems="center">
                                <Grid item xs={2}>
                                    <Tooltip title="Back" disableFocusListener>
                                        <button
                                            className={customStyles.back_button}
                                            onClick={() =>
                                                this.handleBackButtonClick(isFilterSelected)}
                                        >
                                            <i className={customStyles.back_icon} />
                                        </button>
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={7}>
                                    <Tooltip
                                        title={dashcamName}
                                        disableFocusListener
                                        placement="left"
                                    >
                                        <Typography variant="h5" className={`${customStyles.event_list_header_title} ${isAllDashcam ? customStyles.event_filter_selected : ''}`}>
                                            {dashcamName}
                                        </Typography>
                                    </Tooltip>
                                    <Typography variant="h5" className={customStyles.event_list_subheader_title}>
                                        <span>
                                            {isAllDashcam ? `Showing ${paginatedEvents.length} of ${eventResponse.length}` : dashcam.imei}
                                        </span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    {!isAllDashcam &&
                                    <Tooltip
                                        title={dashcam.status}
                                        disableFocusListener
                                        className={customStyles.dashcam_status_container}
                                    >
                                        <span>
                                            <GetSvgIcon
                                                type={dashcam.status === 'offline' ? 'offlineDashcam' : 'dashcamIcon'}
                                                viewBox={dashcam.status === 'offline' ? '0 0 27 22' : '0 0 22 14'}
                                                fillcolor={dashcamStatusColor}
                                                className={customStyles.dashcam_status_icon}
                                            />
                                        </span>
                                    </Tooltip>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} className={customStyles.event_download}>
                            <div style={isEmpty(eventResponse) ? { cursor: 'not-allowed' } : {}}>
                                <IconButton
                                    onClick={() => this.downloadEvent(dashcamName)}
                                    disabled={isEmpty(eventResponse)}
                                    disableTouchRipple={isEmpty(eventResponse)}
                                    sx={{ '&.MuiIconButton-root': { padding: '12px' } }}
                                >
                                    <GetSvgIcon
                                        type="download"
                                        fillcolor={
                                            isEmpty(eventResponse) ?
                                                GRAY_COLOR : WHITE_COLOR}
                                        viewBox="-3 -2 24 24"
                                    />
                                </IconButton>
                            </div>
                        </Grid>
                    </Grid>
                    {/* event list */}
                    <Grid container>
                        <Grid item xs={12}>
                            {!this.state.isLoading && !this.state.isError &&
                            <List
                                data={this.state.paginatedEvents || []}
                                height={50}
                                currentPage={this.state.currentPage}
                                totalNumberofPage={this.state.totalNumberOfPage}
                                pagination={{
                                    isPagination: true,
                                    pageChanged: this.loadNextPage,
                                }}
                                getListItem={this.getEventCardContent}
                                id="eventList"
                            />
                            }
                            { this.state.isLoading &&
                            <AppLoader
                                loaderStyle={{
                                    'padding-top': '10px',
                                    'padding-bottom': '10px',
                                    'text-align': 'center',
                                    display: 'block',
                                }}
                            />
                            }
                            { this.state.isError &&
                            <Typography variant="body1" className={customStyles.error_message}>
                                We are sorry a system error has occurred
                                while processing your request,
                                please try you request again.
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        );
    }
}

export default EventList;
