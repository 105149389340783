import moment from 'moment';
import formJson from './formJson';
import { validateEmail } from '../../../util/alert_utils';
import { convertKilometersToMiles, convertMilesToKilometers, convertFahrenheitToCelsius, convertCelsiusToFahrenheit } from '../../../util/unitConversions_utils';
import { ALERT, ALERT_DETAILS } from '../constants.alerts';
import { convertUnitValueAsPerUserSetting } from '../../../helper-classes/utility-functions';

function formatDateToSave(dateToFormat) {
    // Require Date in format YYYY-MM-DDTHH:MM:SS+TZ_Offset => 2019-01-01T01:01:01+0530
    let formattedDate = dateToFormat;
    const colonPosition = formattedDate.lastIndexOf(':');
    if (colonPosition > 20) {
        const begin = formattedDate.substring(0, colonPosition);
        const end = formattedDate.substring(colonPosition + 1, (formattedDate.length));
        formattedDate = begin + end;
    }
    return formattedDate;
}

function formInitialsJsonPayload(typeId) {
    return {
        accountId: '',
        accountLabel: '',
        active: true,
        alertTypeId: '',
        alertTypeLabel: '',
        alertTypeName: '',
        config: {
            selectedDriverFilters: [],
            timezone: formJson().userTz,
            assetSearchText: null,
            landmarkSearchText: null,
            assetSearchTags: '',
            landmarkSearchTags: null,
            notInAssetGroup: false,
            notInLandmarkGroup: false,
            selectedAssetFilters: [],
            selectedLandmarkFilters: [],
            selectedAssetGroups: [],
            selectedLandmarkGroups: [],
            selectedReportFilters: [],
            selectedAlertFilters: [],
            selectedMaintenanceFilters: [],
            queryFields: [
                'name',
                'serialNumber',
                'assetGroupName',
                'assetName',
                'assetMake',
                'assetVin',
                'assetYear',
                'assetModel',
                'assetTypeName',
                'assetDescription',
                'address',
                'city',
                'state',
                'zip',
                'landmarkName',
                'stockNumber',
                'assetTags',
            ],
            assetSearchTagsIds: [],
        },
        configMap: '',
        createdBy: '',
        dateCreated: null,
        distributionListsFlattened: '',
        distributionListsJSON: '',
        executionOrder: 0,
        lastUpdated: null,
        name: '',
        objectId: '',
        repeatDelay: '',
        typeId,
        updatedBy: '',
        version: '',
        comments: '',
        maintenanceAlertTypeId: '',
        timezone: formJson().userTz,
        recipients: '',
        alertSchedulesJSON: {},
        validAllDays: true,
        validAnyTime: true,
        validSunday: true,
        validMonday: true,
        validTuesday: true,
        validWednesday: true,
        validThursday: true,
        validFriday: true,
        validSaturday: true,
        startDate: formatDateToSave(moment().format()),
    };
}

function handleAssetCriteriaJSON(assetsData) {
    const { assets, groups, tags } = assetsData;
    const selectedAssetFilters = assets && assets.map(item => ({ property: 'assetId', type: 'Long', value: item.assetId }));
    const selectedAssetGroupsArray = groups && groups.map(item => (
        { id: item.id, name: item.name, childNodes: item.childNodes }
    ));
    const searchTagsArray = tags && tags.map(item => `public:${item.tag}`);
    const searchTagsIdsArray = tags && tags.map(item => item.id);
    const parentChildNodeNamesArray = selectedAssetGroupsArray && selectedAssetGroupsArray.map(item => `${item.name},${item.childNodes}`).join(',');
    const assetCriteriaJsonObject = {
        selectedAssetFilters: '',
        selectedAssetGroups: '',
        assetGroupName: '',
        assetSearchTags: '',
        assetSearchTagsIds: '',
    };
    assetCriteriaJsonObject.selectedAssetFilters = selectedAssetFilters;
    assetCriteriaJsonObject.selectedAssetGroups = selectedAssetGroupsArray;
    assetCriteriaJsonObject.assetGroupName = parentChildNodeNamesArray.split(',');
    assetCriteriaJsonObject.assetSearchTags = searchTagsArray.toString();
    assetCriteriaJsonObject.assetSearchTagsIds = searchTagsIdsArray;
    return assetCriteriaJsonObject;
}

function getRecipientArray(recipients) {
    let recipientArray = [];
    let recipientsArray = recipients;
    // Added 'split' as the recipients were got as comma seperated (from earlier array)
    if (typeof recipients === 'string') {
        recipientsArray = recipients.split(',');
    }
    if (recipientsArray && recipientsArray.length > 0) {
        recipientArray = recipientsArray.map((item) => {
            const recipientJsonObject = {
                email: '',
                phoneNumber: '',
            };

            if (validateEmail(item)) {
                recipientJsonObject.email = item;
                recipientJsonObject.phoneNumber = '';
            } else {
                recipientJsonObject.email = '';
                recipientJsonObject.phoneNumber = item;
            }
            return recipientJsonObject;
        });
    }
    return recipientArray;
}

function getAssetCriteria(configs) {
    const assets = configs.selectedAssetFilters ?
        configs.selectedAssetFilters.map(item => ({ assetId: item.value })) : [];
    const groups = configs.selectedAssetGroups || [];
    const tags = [];
    const tagNames = configs.assetSearchTags ? configs.assetSearchTags.replace(/public:/g, '').split(',') : [];
    for (let index = 0; index < tagNames.length; index += 1) {
        if (configs.assetSearchTagsIds[index]) {
            tags.push({
                id: configs.assetSearchTagsIds[index],
                tag: tagNames[index],
                tagType: 'asset',
            });
        }
    }
    let type = 'custom';
    if (assets.length === 0) {
        if (groups.length === 0 && tags.length === 0) {
            type = 'all-assets';
        } else {
            type = 'groups-tags';
        }
    }
    const assetCriteria = {
        assets,
        // groups: [{ id: '71937', name: 'MyAssetGroup' }],
        groups,
        // tags: [{ id: 3233, tag: 111 }, { id: 3178, tag: 1234 }],
        tags,
        // type: all-assets/groups-tags/custom,
        type,
    };
    return assetCriteria;
}

export const formJsonPayload = (alertType, typeId) => {
    const requestPayload = formInitialsJsonPayload(typeId);
    switch (alertType) {
    case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
        requestPayload.config = {
            alertBraking: true,
            alertAcceleration: true,
            ...requestPayload.config,
        };
        break;
    case ALERT.ODD_HOURS_ALERT.CODE:
        requestPayload.config = {
            realert: false,
            realertMinutes: ALERT_DETAILS.VALUE.OHM_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
        requestPayload.config = {
            speedLimitExceedMPH: ALERT_DETAILS.VALUE.PSL_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.SPEED_THRESHOLD_ALERT.CODE:
        requestPayload.config = {
            speedThresholdMPH: ALERT_DETAILS.VALUE.ST_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
        break;
    case ALERT.SEATBELT_STATUS_ALERT.CODE:
        requestPayload.config = {
            seatBeltMinutes: ALERT_DETAILS.VALUE.SBLT_DEF,
            realert: false,
            realertMinutes: ALERT_DETAILS.VALUE.SBLT_R_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.IDLE_ALERT.CODE:
        requestPayload.config = {
            idleMinutes: ALERT_DETAILS.VALUE.IDL_DEF,
            realert: false,
            realertMinutes: ALERT_DETAILS.VALUE.IDL_R_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.STOP_ALERT.CODE:
        requestPayload.config = {
            stopDays: ALERT_DETAILS.VALUE.STD_DEF,
            stopHours: ALERT_DETAILS.VALUE.STH_DEF,
            stopMinutes: ALERT_DETAILS.VALUE.STM_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.INPUT_ALERT.CODE:
        requestPayload.config = {
            input1Active: false,
            input1Inactive: false,
            input2Active: false,
            input2Inactive: false,
            input3Active: false,
            input3Inactive: false,
            input4Active: false,
            input4Inactive: false,
            ...requestPayload.config,
        };
        break;
    case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
        requestPayload.config = {
            alertArrival: true,
            alertDeparture: true,
            ...requestPayload.config,
        };
        break;
    case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
        break;
    case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
        break;
    case ALERT.TEMPERATURE_ALERT.CODE:
        requestPayload.config = {
            criticalMinTemperature: ALERT_DETAILS.VALUE.CRT_MIN_DEF,
            criticalMaxTemperature: ALERT_DETAILS.VALUE.CRT_MAX_DEF,
            cautionMinTemperature: ALERT_DETAILS.VALUE.CUT_MIN_DEF,
            cautionMaxTemperature: ALERT_DETAILS.VALUE.CUT_MAX_DEF,
            rampTime: ALERT_DETAILS.VALUE.RT_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.FUEL_TANK_ALERT.CODE:
        requestPayload.config = {
            fuelTankThreshold: ALERT_DETAILS.VALUE.FT_DEF,
            realert: 'off',
            realertMinutes: ALERT_DETAILS.VALUE.FT_RT_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.DTC_ALERT.CODE:
        break;
    case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
        requestPayload.config = {
            alertIntLowBattery: false,
            ...requestPayload.config,
        };
        break;
    // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
    //     break;
    case ALERT.TIRE_PRESSURE_ALERT.CODE:
        requestPayload.config = {
            alertOverInflationDaysCheck: false,
            alertUnderInflationDaysCheck: false,
            realert: false,
            realertDays: ALERT_DETAILS.VALUE.TP_DEF,
            ...requestPayload.config,
        };
        break;
    case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
        requestPayload.config = {
            realert: false,
            realertDays: ALERT_DETAILS.VALUE.EO_DEF,
            ...requestPayload.config,
        };
        break;
    default:
        break;
    }
    return requestPayload;
};

export const requestJsonPayload = (alertType, formValues, assetCriteria) => {
    const requestPayload = formValues;
    let { config } = requestPayload;

    switch (alertType) {
    case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
        break;
    case ALERT.ODD_HOURS_ALERT.CODE:
        if (config.realert) {
            config.realert = 'on';
        } else {
            delete config.realert;
            delete config.realertMinutes;
        }
        break;
    case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
        if (formJson().userUnits !== 'miles') {
            config.speedLimitExceedMPH = convertKilometersToMiles(config.speedLimitExceedMPH);
        }
        break;
    case ALERT.SPEED_THRESHOLD_ALERT.CODE:
        if (formJson().userUnits !== 'miles') {
            config.speedThresholdMPH = convertKilometersToMiles(config.speedThresholdMPH);
        }
        break;
    case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
        break;
    // case ALERT.SEATBELT_STATUS_ALERT.CODE:
    //     break;
    case ALERT.IDLE_ALERT.CODE:
        if (config.realert) {
            config.realert = 'on';
        } else {
            delete config.realert;
            delete config.realertMinutes;
        }
        break;
    case ALERT.STOP_ALERT.CODE:
        config.stopDays = config.stopDays ? config.stopDays : 0;
        config.stopHours = config.stopHours ? config.stopHours : 0;
        config.stopMinutes = config.stopMinutes ? config.stopMinutes : 0;
        break;
    case ALERT.INPUT_ALERT.CODE:
        break;
    case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
        config.alertArrival = config.alertArrival ? 'on' : 'off';
        config.alertDeparture = config.alertDeparture ? 'on' : 'off';
        if (config.selectedLandmarkFilters.length > 0) {
            const landmarkFilters = config.selectedLandmarkFilters.map(landmark => ({
                property: 'landmarkId',
                type: 'Long',
                value: landmark.id,
            }));
            config.selectedLandmarkFilters = landmarkFilters;
        }
        break;
    case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
        break;
    case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
        break;
    case ALERT.TEMPERATURE_ALERT.CODE:
        config.criticalMinTemperature = convertFahrenheitToCelsius(config.criticalMinTemperature);
        config.criticalMaxTemperature = convertFahrenheitToCelsius(config.criticalMaxTemperature);
        config.cautionMinTemperature = convertFahrenheitToCelsius(config.cautionMinTemperature);
        config.cautionMaxTemperature = convertFahrenheitToCelsius(config.cautionMaxTemperature);
        config.rampTime = `${config.rampTime}`;
        break;
    case ALERT.FUEL_TANK_ALERT.CODE:
        if (config.realert === 'off') {
            delete config.realertMinutes;
        }
        break;
    case ALERT.DTC_ALERT.CODE:
        break;
    case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
        if (config.alertIntLowBattery) {
            config.alertIntLowBattery = 'on';
        } else {
            delete config.alertIntLowBattery;
        }
        break;
    // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
    //     break;
    case ALERT.TIRE_PRESSURE_ALERT.CODE:
        if (config.alertOverInflationDaysCheck) {
            config.alertOverInflationDaysCheck = 'on';
        } else {
            delete config.alertOverInflationDaysCheck;
        }
        if (config.alertUnderInflationDaysCheck) {
            config.alertUnderInflationDaysCheck = 'on';
        } else {
            delete config.alertUnderInflationDaysCheck;
        }
        if (config.realert) {
            config.realert = 'on';
            config.realertDays = `${config.realertDays}`;
        } else {
            delete config.realert;
        }
        break;
    case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
        if (config.realert) {
            config.realert = 'on';
            config.realertDays = `${config.realertDays}`;
        } else {
            delete config.realert;
            delete config.realertDays;
        }
        break;
    default:
        break;
    }
    // AssetCriteria State Update
    const assetsData = handleAssetCriteriaJSON(assetCriteria);
    config.selectedAssetFilters = assetsData.selectedAssetFilters;
    config.selectedAssetGroups = assetsData.selectedAssetGroups;
    config.assetGroupName = assetsData.assetGroupName;
    config.assetSearchTags = assetsData.assetSearchTags;
    config.assetSearchTagsIds = assetsData.assetSearchTagsIds;
    if (config.assetGroupName) {
        delete config.assetGroupName;
    }

    // If Schedules are selected
    if (Object.keys(requestPayload.alertSchedulesJSON).length > 0) {
        requestPayload.validAllDays = false;
        requestPayload.validAnyTime = false;
        requestPayload.validSunday = false;
        requestPayload.validMonday = false;
        requestPayload.validTuesday = false;
        requestPayload.validWednesday = false;
        requestPayload.validThursday = false;
        requestPayload.validFriday = false;
        requestPayload.validSaturday = false;
    } else {
        requestPayload.alertSchedulesJSON = '{}';
    }

    // While editing the Alert, ensure that the payload is as per old app
    if (requestPayload.edit) {
        delete requestPayload.edit;

        requestPayload.configMap = config;
        requestPayload.distributionListsFlattened = '';
        requestPayload.maintenanceAlertTypeId = '';

        if (requestPayload.executionTime !== undefined) {
            delete requestPayload.executionTime;
        }
        if (requestPayload.globalId !== undefined) {
            delete requestPayload.globalId;
        }
        if (requestPayload.lastRunDate !== undefined) {
            delete requestPayload.lastRunDate;
        }
        if (requestPayload.nextRunDate !== undefined) {
            delete requestPayload.nextRunDate;
        }
        if (requestPayload.endDate !== undefined) {
            delete requestPayload.endDate;
        }
        if (requestPayload.objectType !== undefined) {
            delete requestPayload.objectType;
        }
        if (requestPayload.validEndTime !== undefined) {
            delete requestPayload.validEndTime;
        }
        if (requestPayload.validStartTime !== undefined) {
            delete requestPayload.validStartTime;
        }
    }

    config = JSON.stringify(config);
    requestPayload.config = config;

    return requestPayload;
};

export const canSaveAlert = (alertType, config) => {
    let validationError = '';
    switch (alertType) {
    case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
        if (!config.alertBraking && !config.alertAcceleration) {
            validationError = ALERT_DETAILS.MESSAGES.HARD_ACCELERATION_VALID;
        }
        break;
    case ALERT.ODD_HOURS_ALERT.CODE:
        if (config.realert) {
            if (config.realertMinutes < ALERT_DETAILS.VALUE.OHM_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.ODD_HOURS_LESS_MIN +
                    ALERT_DETAILS.VALUE.OHM_MIN;
            } else if (config.realertMinutes > ALERT_DETAILS.VALUE.OHM_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.ODD_HOURS_GREAT_MAX +
                    ALERT_DETAILS.VALUE.OHM_MAX;
            }
        }
        break;
    case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE: {
        const minValue = convertUnitValueAsPerUserSetting(ALERT_DETAILS.VALUE.PSL_MIN, 'round');
        if (config.speedLimitExceedMPH < minValue) {
            validationError = ALERT_DETAILS.MESSAGES.SPEED_LIMIT_LESS_MIN +
                minValue;
        } else if (config.speedLimitExceedMPH > ALERT_DETAILS.VALUE.PSL_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.SPEED_LIMIT_GREAT_MAX +
                ALERT_DETAILS.VALUE.PSL_MAX;
        }
        break;
    }
    case ALERT.SPEED_THRESHOLD_ALERT.CODE: {
        const minValue = convertUnitValueAsPerUserSetting(ALERT_DETAILS.VALUE.ST_MIN, 'round');
        if (config.speedThresholdMPH < minValue) {
            validationError = ALERT_DETAILS.MESSAGES.SPEED_THRESHOLD_LESS_MIN +
                minValue;
        } else if (config.speedThresholdMPH > ALERT_DETAILS.VALUE.ST_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.SPEED_THRESHOLD_GREAT_MAX +
                ALERT_DETAILS.VALUE.ST_MAX;
        }
        break;
    }
    case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
        break;
    case ALERT.SEATBELT_STATUS_ALERT.CODE:
        if (config.seatBeltMinutes < ALERT_DETAILS.VALUE.SBLT_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.SBLT_LESS_MIN +
                ALERT_DETAILS.VALUE.SBLT_MIN;
        } else if (config.seatBeltMinutes > ALERT_DETAILS.VALUE.SBLT_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.SBLT_GREAT_MAX +
                ALERT_DETAILS.VALUE.SBLT_MAX;
        } else if (config.realert) {
            if (config.realertMinutes < ALERT_DETAILS.VALUE.SBLT_R_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.SBLT_REALERT_LESS_MIN +
                    ALERT_DETAILS.VALUE.SBLT_R_MIN;
            } else if (config.realertMinutes > ALERT_DETAILS.VALUE.SBLT_R_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.SBLT_REALERT_GREAT_MAX +
                    ALERT_DETAILS.VALUE.SBLT_R_MAX;
            }
        }
        break;
    case ALERT.IDLE_ALERT.CODE:
        if (config.idleMinutes < ALERT_DETAILS.VALUE.IDL_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.IDLE_LESS_MIN +
                ALERT_DETAILS.VALUE.IDL_MIN;
        } else if (config.idleMinutes > ALERT_DETAILS.VALUE.IDL_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.IDLE_GREAT_MAX +
                ALERT_DETAILS.VALUE.IDL_MAX;
        } else if (config.realert) {
            if (config.realertMinutes < ALERT_DETAILS.VALUE.IDL_R_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.IDLE_REALERT_LESS_MIN +
                    ALERT_DETAILS.VALUE.IDL_R_MIN;
            } else if (config.realertMinutes > ALERT_DETAILS.VALUE.IDL_R_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.IDLE_REALERT_GREAT_MAX +
                    ALERT_DETAILS.VALUE.IDL_R_MAX;
            }
        }
        break;
    case ALERT.STOP_ALERT.CODE:
        if (config.stopDays <= ALERT_DETAILS.VALUE.STD_MIN &&
            config.stopHours <= ALERT_DETAILS.VALUE.STH_MIN) {
            if (config.stopMinutes < ALERT_DETAILS.VALUE.STM_DEF) {
                validationError = ALERT_DETAILS.MESSAGES.STOP_MINUTES_MIN;
            }
        } else if (config.stopDays < ALERT_DETAILS.VALUE.STD_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_DAYS_LESS_MIN +
                ALERT_DETAILS.VALUE.STD_MIN;
        } else if (config.stopDays > ALERT_DETAILS.VALUE.STD_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_DAYS_GREAT_MAX +
                ALERT_DETAILS.VALUE.STD_MAX;
        } else if (config.stopHours < ALERT_DETAILS.VALUE.STH_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_HOURS_LESS_MIN +
                ALERT_DETAILS.VALUE.STH_MIN;
        } else if (config.stopHours > ALERT_DETAILS.VALUE.STH_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_HOURS_GREAT_MAX +
                ALERT_DETAILS.VALUE.STH_MAX;
        } else if (config.stopMinutes < ALERT_DETAILS.VALUE.STM_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_MINUTES_LESS_MIN +
                ALERT_DETAILS.VALUE.STM_MIN;
        } else if (config.stopMinutes > ALERT_DETAILS.VALUE.STM_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.STOP_MINUTES_GREAT_MAX +
                ALERT_DETAILS.VALUE.STM_MAX;
        }
        break;
    case ALERT.INPUT_ALERT.CODE:
        if (!config.input1Active && !config.input1Inactive &&
            !config.input2Active && !config.input2Inactive &&
            !config.input3Active && !config.input3Inactive &&
            !config.input4Active && !config.input4Inactive) {
            validationError = ALERT_DETAILS.MESSAGES.INPUT_VALID;
        }
        break;
    case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
        if (!config.alertArrival && !config.alertDeparture) {
            validationError = ALERT_DETAILS.MESSAGES.LANDMARK_ARRIVAL_DEPARTURE_VALID;
        }
        break;
    case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
        break;
    case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
        break;
    case ALERT.TEMPERATURE_ALERT.CODE: {
        const criticalMinTemp = config.criticalMinTemperature ?
            parseInt(config.criticalMinTemperature, 10) : 0;
        const criticalMaxTemp = config.criticalMaxTemperature ?
            parseInt(config.criticalMaxTemperature, 10) : 0;
        const cautionMinTemp = config.cautionMinTemperature ?
            parseInt(config.cautionMinTemperature, 10) : 0;
        const cautionMaxTemp = config.cautionMaxTemperature ?
            parseInt(config.cautionMaxTemperature, 10) : 0;
        const rampTime = config.rampTime ? parseInt(config.rampTime, 10) : 0;
        if (criticalMinTemp <= ALERT_DETAILS.VALUE.CRT_MIN_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.CRT_MIN_LESS_MIN +
                ALERT_DETAILS.VALUE.CRT_MIN_MIN;
        } else if (criticalMinTemp >= ALERT_DETAILS.VALUE.CRT_MIN_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.CRT_MIN_GREAT_MAX +
                ALERT_DETAILS.VALUE.CRT_MIN_MAX;
        } else if (criticalMaxTemp <= ALERT_DETAILS.VALUE.CRT_MAX_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.CRT_MAX_LESS_MIN +
                ALERT_DETAILS.VALUE.CRT_MAX_MIN;
        } else if (criticalMaxTemp >= ALERT_DETAILS.VALUE.CRT_MAX_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.CRT_MAX_GREAT_MAX +
                ALERT_DETAILS.VALUE.CRT_MAX_MAX;
        } else if (cautionMinTemp <= ALERT_DETAILS.VALUE.CUT_MIN_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MIN_LESS_MIN +
                ALERT_DETAILS.VALUE.CUT_MIN_MIN;
        } else if (cautionMinTemp >= ALERT_DETAILS.VALUE.CUT_MIN_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MIN_GREAT_MAX +
                ALERT_DETAILS.VALUE.CUT_MIN_MAX;
        } else if (cautionMaxTemp <= ALERT_DETAILS.VALUE.CUT_MAX_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MAX_LESS_MIN +
                ALERT_DETAILS.VALUE.CUT_MAX_MIN;
        } else if (cautionMaxTemp >= ALERT_DETAILS.VALUE.CUT_MAX_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MAX_GREAT_MAX +
                ALERT_DETAILS.VALUE.CUT_MAX_MAX;
        } else if (rampTime < ALERT_DETAILS.VALUE.RT_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.RT_LESS_MIN +
                ALERT_DETAILS.VALUE.RT_MIN;
        } else if (rampTime >= ALERT_DETAILS.VALUE.RT_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.RT_GREAT_MAX +
                ALERT_DETAILS.VALUE.RT_MAX;
        } else if (criticalMinTemp >= criticalMaxTemp) {
            validationError = ALERT_DETAILS.MESSAGES.CRT_MIN_LESS_MAX;
        } else if (cautionMinTemp >= cautionMaxTemp) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MIN_LESS_MAX;
        } else if (cautionMinTemp <= criticalMinTemp) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MIN_LESS_CRT_MIN;
        } else if (cautionMaxTemp >= criticalMaxTemp) {
            validationError = ALERT_DETAILS.MESSAGES.CUT_MAX_GREAT_CRT_MAX;
        }
        break;
    }
    case ALERT.FUEL_TANK_ALERT.CODE:
        if (config.fuelTankThreshold < ALERT_DETAILS.VALUE.FT_MIN) {
            validationError = ALERT_DETAILS.MESSAGES.FT_LESS_MIN +
                ALERT_DETAILS.VALUE.FT_MIN;
        } else if (config.fuelTankThreshold > ALERT_DETAILS.VALUE.FT_MAX) {
            validationError = ALERT_DETAILS.MESSAGES.FT_GREAT_MAX +
                ALERT_DETAILS.VALUE.FT_MAX;
        } else if (config.realert === 'on') {
            if (config.realertMinutes < ALERT_DETAILS.VALUE.FT_RT_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.FT_REALERT_LESS_MIN +
                    ALERT_DETAILS.VALUE.FT_RT_MIN;
            } else if (config.realertMinutes > ALERT_DETAILS.VALUE.FT_RT_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.FT_REALERT_GREAT_MAX +
                    ALERT_DETAILS.VALUE.FT_RT_MAX;
            }
        }
        break;
    case ALERT.DTC_ALERT.CODE:
        break;
    case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
        break;
    // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
    //     break;
    case ALERT.TIRE_PRESSURE_ALERT.CODE:
        if (!config.alertOverInflationDaysCheck && !config.alertUnderInflationDaysCheck) {
            validationError = ALERT_DETAILS.MESSAGES.TP_VALID;
        } else if (config.realert) {
            if (config.realertDays < ALERT_DETAILS.VALUE.TP_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.TP_REALERT_LESS_MIN +
                    ALERT_DETAILS.VALUE.TP_MIN;
            } else if (config.realertDays > ALERT_DETAILS.VALUE.TP_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.TP_REALERT_GREAT_MAX +
                    ALERT_DETAILS.VALUE.TP_MAX;
            }
        }
        break;
    case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
        if (config.realert) {
            if (config.realertDays < ALERT_DETAILS.VALUE.EO_MIN) {
                validationError = ALERT_DETAILS.MESSAGES.EO_REALERT_LESS_MIN +
                    ALERT_DETAILS.VALUE.EO_MIN;
            } else if (config.realertDays > ALERT_DETAILS.VALUE.EO_MAX) {
                validationError = ALERT_DETAILS.MESSAGES.EO_REALERT_GREAT_MAX +
                    ALERT_DETAILS.VALUE.EO_MAX;
            }
        }
        break;
    default:
        break;
    }
    return validationError;
};

export const formJsonPayloadEdit = (savedAlert, alertType) => {
    const jsonPayload = Object.assign({}, savedAlert);
    const config = JSON.parse(jsonPayload.config);
    delete jsonPayload.configMap;

    switch (alertType) {
    case ALERT.HARD_BRAKING_ACCELERATION_ALERT.CODE:
        break;
    case ALERT.ODD_HOURS_ALERT.CODE:
        if (config.realert !== undefined && config.realert === 'on') {
            config.realert = true;
        } else {
            config.realert = false;
            config.realertMinutes = ALERT_DETAILS.VALUE.OHM_DEF;
        }
        break;
    case ALERT.POSTED_SPEED_LIMIT_ALERT.CODE:
        if (formJson().userUnits !== 'miles') {
            config.speedLimitExceedMPH = convertMilesToKilometers(config.speedLimitExceedMPH);
        }
        break;
    case ALERT.SPEED_THRESHOLD_ALERT.CODE:
        if (formJson().userUnits !== 'miles') {
            config.speedThresholdMPH = convertMilesToKilometers(config.speedThresholdMPH);
        }
        break;
    case ALERT.UNAUTHORIZED_MOVEMENT.CODE:
        break;
    // case ALERT.SEATBELT_STATUS_ALERT.CODE:
    //     break;
    case ALERT.IDLE_ALERT.CODE:
        if (config.realert !== undefined && config.realert === 'on') {
            config.realert = true;
        } else {
            config.realert = false;
            config.realertMinutes = ALERT_DETAILS.VALUE.IDL_DEF;
        }
        break;
    case ALERT.STOP_ALERT.CODE:
        break;
    case ALERT.INPUT_ALERT.CODE:
        break;
    case ALERT.LANDMARK_ARRIVAL_DEPARTURE_ALERT.CODE:
        if (config.alertArrival !== undefined && config.alertArrival === 'on') {
            config.alertArrival = true;
        } else {
            config.alertArrival = false;
        }
        if (config.alertDeparture !== undefined && config.alertDeparture === 'on') {
            config.alertDeparture = true;
        } else {
            config.alertDeparture = false;
        }
        break;
    case ALERT.EXTERNAL_DEVICE_POWER_ON_ALERT.CODE:
        break;
    case ALERT.DEVICE_POWER_DISCONNECT_ALERT.CODE:
        break;
    case ALERT.TEMPERATURE_ALERT.CODE:
        config.criticalMinTemperature = convertCelsiusToFahrenheit(config.criticalMinTemperature);
        config.criticalMaxTemperature = convertCelsiusToFahrenheit(config.criticalMaxTemperature);
        config.cautionMinTemperature = convertCelsiusToFahrenheit(config.cautionMinTemperature);
        config.cautionMaxTemperature = convertCelsiusToFahrenheit(config.cautionMaxTemperature);
        config.rampTime = parseInt(config.rampTime, 10);
        break;
    case ALERT.FUEL_TANK_ALERT.CODE:
        if (config.realert === 'off') {
            config.realertMinutes = ALERT_DETAILS.VALUE.FT_RT_DEF;
        }
        break;
    case ALERT.DTC_ALERT.CODE:
        break;
    case ALERT.LOW_ASSET_BATTERY_ALERT.CODE:
        if (config.alertIntLowBattery !== undefined && config.alertIntLowBattery === 'on') {
            config.alertIntLowBattery = true;
        } else {
            config.alertIntLowBattery = false;
        }
        break;
    // case ALERT.LOW_DEVICE_BATTERY_ALERT.CODE:
    //     break;
    case ALERT.TIRE_PRESSURE_ALERT.CODE:
        if (config.alertOverInflationDaysCheck !== undefined &&
            config.alertOverInflationDaysCheck === 'on') {
            config.alertOverInflationDaysCheck = true;
        } else {
            config.alertOverInflationDaysCheck = false;
        }
        if (config.alertUnderInflationDaysCheck !== undefined &&
            config.alertUnderInflationDaysCheck === 'on') {
            config.alertUnderInflationDaysCheck = true;
        } else {
            config.alertUnderInflationDaysCheck = false;
        }
        if (config.realert !== undefined && config.realert === 'on') {
            config.realert = true;
        } else {
            config.realert = false;
        }
        break;
    case ALERT.ENGINE_OIL_LIFE_ALERT.CODE:
        if (config.realert !== undefined && config.realert === 'on') {
            config.realert = true;
        } else {
            config.realert = false;
            config.realertDays = ALERT_DETAILS.VALUE.EO_DEF;
        }
        break;
    default:
        break;
    }

    jsonPayload.config = config;

    if (jsonPayload.alertSchedulesJSON === undefined ||
        jsonPayload.alertSchedulesJSON === null ||
        jsonPayload.alertSchedulesJSON === '{}') {
        jsonPayload.alertSchedulesJSON = {};
    }

    jsonPayload.startDate = formatDateToSave(moment(jsonPayload.startDate).format());
    jsonPayload.dateCreated = formatDateToSave(moment(jsonPayload.dateCreated).format());
    jsonPayload.lastUpdated = formatDateToSave(moment(jsonPayload.lastUpdated).format());
    jsonPayload.edit = true;
    const recipientArray = getRecipientArray(jsonPayload.recipients);
    const assetCriteria = getAssetCriteria(jsonPayload.config);
    return { jsonPayload, assetCriteria, recipientArray };
};
