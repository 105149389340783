import { combineEpics } from 'redux-observable';
import * as constants from './constants.driverSettings';
import * as actions from './actions.driverSettings';
import config from '../../../constants/Config';
import { getIframeUserToken } from '../../../helper-classes/utility-functions';

const querystring = require('querystring');

type ObservarblesTypes = {
    getJSON: Function,
    postJSON: Function,
    put: Function,
    of: Function,
}

function getDriverSettingsApiUrl(action) {
    const { payload } = action;
    let url = config.get('DRIVER_SCORECARD_SERVICES_URL');
    let urlConfig = {};
    switch (action.type) {
    case constants.ADD_DRIVER_SETTINGS:
        return `${url}/scorecard/settings`;
    case constants.FETCH_DRIVER_SETTINGS: {
        delete payload.id;
        urlConfig = Object.assign({}, payload);
        url = `${url}/scorecard/settings?`;
        return `${url}${querystring.stringify(urlConfig)}`;
    }
    case constants.UPDATE_DRIVER_SETTINGS:
        return `${url}/scorecard/settings`;
    default:
        return '';
    }
}

export const saveDriverSettings =
    (actions$: Function, store: Object, { postJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.ADD_DRIVER_SETTINGS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };

                return postJSON(getDriverSettingsApiUrl(action), action.payload, headers)
                    .map((result) => {
                        if (Object.keys(result).length > 0) {
                            return actions.addDriverSettingsSuccess(result.response);
                        }
                        return actions.addDriverSettingsError(result);
                    })
                    .catch(error => of(actions.addDriverSettingsError(error)));
            });

export const getDriverSettings =
    (actions$: Function, store: Object, { getJSON, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.FETCH_DRIVER_SETTINGS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };
                return getJSON(getDriverSettingsApiUrl(action), headers);
            })
            .map(result => actions.fetchDriverSettingsSuccess(result))
            .catch(error => of(actions.fetchDriverSettingsError(error)));

export const editDriverSettings =
    (actions$: Function, store: Object, { put, of }: ObservarblesTypes) =>
        actions$
            .ofType(constants.UPDATE_DRIVER_SETTINGS)
            .mergeMap((action) => {
                const headers = {
                    'X-Nspire-UserToken': getIframeUserToken(),
                    'Content-Type': 'application/json',
                };

                return put(getDriverSettingsApiUrl(action), action.payload, headers)
                    .map((result) => {
                        if (Object.keys(result).length > 0) {
                            return actions.updateDriverSettingsSuccess(result);
                        }
                        return actions.updateDriverSettingsError(result);
                    })
                    .catch(error => of(actions.updateDriverSettingsError(error)));
            });

export default combineEpics(
    saveDriverSettings,
    getDriverSettings,
    editDriverSettings,
);
