/* @flow */
export const HLS_CONFIG: Object = {
    liveSyncDurationCount: 3,
    liveMaxLatencyDurationCount: 10,
};

export const WEBRTC_CONFIG: Object = {
    rtc_configuration: {
        iceServers: [
            { urls: 'stun:stun.services.mozilla.com' },
            { urls: 'stun:stun.l.google.com:19302' },
        ],
    },
    default_constraints: { video: true, audio: false },
};

export const LIVE_VIDEO_CONFIG: Object = {
    defaultVideoType: 'webRtc',
    isPlaying: true,
};

export const SPA_USER_TOKEN = 'spaUserToken';
export const SPA_ORG_ID = 'spaOrgId';
export const DASHCAM_MEDIA_TOKEN_COOKIE = 'dashcam_mtc';
export const MEDIA_TOKEN_EXPIRY_IN_MINS = 2;
export const RECORDING_VIDEO_DURATION_IN_SEC = 30;
export const VIDEO_NORMAL_PLAYBACK_RATE = 1;
export const VIDEO_FAST_PLAYBACK_RATE = 2;
export const RECORDING_DOWNLOAD_BEFORE_AFTER_TIME_SLOTS_IN_SEC =
    [0, 5, 10, 20, 30, 45, 60, 90, 120];
export const DASHCAM_LIST_DEFAULT_ITEM_LIMIT = 30;
export const DASHCAM_LIST_DEFAULT_PARALLEL_CHUNK_SIZE = 5;
export const DASHCAM_EVENT_DEFAULT_PARALLEL_CHUNK_SIZE = 5;
export const DASHCAM_LIST_AUTO_REFRESH_TIME_IN_MS = 30000;

export const DASHCAM_ANALYTICS = 'Dashcam';
export const LIGHT_METRICS_DASHCAM_ANALYTICS = 'Light Metrics Dashcam';
export const DASHCAM_LIST = 'Dashcam List';
export const SEARCH_DASHCAM = 'Search Dashcam';
export const CANCEL_ADD_DASHCAM = 'Cancel Add Dashcam';
export const SAVE_ADD_DASHCAM = 'Save Add Dashcam';
export const DASHCAM_EVENT_LIST = 'Event List';
export const DASHCAM_LIVE_VIEW_STREAM = 'Live Stream';
export const DASHCAM_LIVE_VIEW = 'Live View';
export const DASHCAM_EDIT = 'Edit Dashcam';
export const DASHCAM_RECORDING = 'Recording Dashcam';
export const DASHCAM_REFRESH = 'Refresh Dashcam List';
export const DELETE_DASHCAM = 'Delete Dashcam';
export const DASHCAM_SETTING = 'Dashcam Setting';
export const DASHCAM_EVENT_SNAPSHOT = 'Dashcam Event Snapshot';
export const DASHCAM_EVENT_VIDEO = 'Dashcam Event Video';
export const DASHCAM_EVENT_SETTING = 'Dashcam Event Setting';
export const NORMAL_QUALITY_TOOLTIP = 'Provides faster streaming and reduced data usage at lower frame rates. Playback speed is 2x';
export const HIGH_QUALITY_TOOLTIP = 'Provides slower streaming and high data usage at recorded frame rates.';
export const HIGH_QUALITY_TEXT = 'High Frame Rate';
export const NORMAL_QUALITY_TEXT = 'Reduced Frame Rate';
export const ERROR_MSG_ADD_DASHCAM_EVENT_SETTING_FAIL = 'Dashcam added successfully, but events could not be turned on. Please turn them on manually in the events settings page.';
export const ADD_DASHCAM_SUCCESS = 'Dashcam device added successfully.';
export const ADD_DASHCAM_ERROR_INVALID_CAMERA_IMEI = 'is not a valid IMEI. The IMEI can be found on the dashcam label.';
export const EVENT_SUMMARY_DEFAULT = 'Event Summary Default';
export const EVENT_SUMMARY_DASHCAM = 'Event Summary Dashcam';
export const EVENT_SUMMARY_ALL_DASHCAM = 'Event Summary AllDashcams';
export const DATE_RANGE = 'DATE_RANGE';
export const NONE = 'None';
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const DATE_FILTER_CHANGE = 'DATE_FILTER_CHANGE';
export const CARD_FILTER_CHANGE = 'CARD_FILTER_CHANGE';
export const DASHCAM_EVENT_ICON_CLICK = 'DASHCAM_EVENT_ICON_CLICK';
export const DASHCAM_EVENT_VIEW = 'DASHCAM_EVENT_VIEW';
export const EVENT_SETTINGS = 'EVENT_SETTINGS';
export const BACK_TO_EVENT_DEFAULT_PAGE = 'BACK_TO_EVENT_DEFAULT_PAGE';
