/* @flow */
import React, { Component } from 'react';
import { Card, CardContent, Typography, InputAdornment, Tooltip } from '@mui/material';
import moment from 'moment';
import { reduxConnect } from '../../hoc';
import GetSvgIcon from '../../util/svgImage_util';
import customStyles from './DashCam.module.scss';
import CameraRecordings from './Recordings/CameraRecordings';
import { getDashCamUserToken, getOrganizationDevices, getDeviceEvents, getAllDeviceForEvent, getEventByImei } from './helper-classes/dashcamServices';
import { setDashcamToken, isDashcamAuthenticated, buildEventObject } from '../../util/dashcam_util';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';
import SearchBox from '../../components/SharedComponents/SearchBox';
import LiveVideos from '../../components/DashCam/LiveVideos/LiveVideosList';
import EditDashcam from './../../components/DashCam/EditDashcam/EditDashcam';
import DashcamList from '../../components/DashCam/List/DashcamList';
import analytics from '../../analytics/index';
import * as notificationActions from '../../components/SharedComponents/NotificationHandler/actions.appNotifications';
import EventList from '../../components/DashCam/Events/EventList';
import EventSummary from '../../components/DashCam/Events/EventSummary';
import AppLoader from '../../components/AppLoader';
import EventVideo from '../../components/DashCam/Events/EventVideo';
import { isEmpty } from '../../helper-classes/utility-functions';
import { SUPPORTED_EVENT_TYPES, HARD_CORNERING_EVENT_TYPES, EVENT_TYPE_MAPPING } from './../../components/DashCam/Events/constants';
import {
    DASHCAM_LIST_DEFAULT_ITEM_LIMIT,
    DASHCAM_LIST_AUTO_REFRESH_TIME_IN_MS,
    DASHCAM_ANALYTICS,
    SEARCH_DASHCAM,
    DASHCAM_REFRESH,
    EVENT_SUMMARY_DEFAULT,
    EVENT_SUMMARY_ALL_DASHCAM,
    EVENT_SUMMARY_DASHCAM,
    DATE_RANGE,
    NONE,
    GET_ALL_EVENTS,
    DATE_FILTER_CHANGE,
    CARD_FILTER_CHANGE,
    DASHCAM_EVENT_ICON_CLICK,
    DASHCAM_EVENT_VIEW,
    DASHCAM_EVENT_SETTING,
    EVENT_SETTINGS,
    BACK_TO_EVENT_DEFAULT_PAGE,
} from '../../containers/DashCam/constants.dashcam';
import { getConvertedStartDate } from '../../util/trip_util';

export type Props = {
    history: {
        push: Function,
        replace: Function,
    },
    appNotificationHandler: Function,
};

export type State = {
    appliedFiltersObj: Object,
    selectedRecordingDashcamIMEI: string,
    isSearchOpen: boolean,
    searchKeyword: string,
    searchCalled: boolean,
    openRightDrawer: boolean,
    showRefreshButton: boolean,
    isDashcamListUpdating: boolean,
    orgDevices: Object,
    selectedView: string,
    events: any,
    isLoader: boolean,
    eventVideoPlayerEvent: Object,
    isEventListError: boolean,
    filterEvents: any,
    isComparisonVisible: boolean,
    comparisionText: string,
    currentEventsCount: any,
    historyEventsCount: any,
    filterEventObj: Object,
};

const snackbarNotificationObj = {
    type: 'snackbar',
    isOpen: false,
    overrideConfig: {
        key: 'header-notification',
        autoHideDuration: 3000,
        action: undefined,
    },
    nConfig: {
        vertical: 'top',
        horizontal: 'right',
    },
};

const loaderStyle = { position: 'absolute !important', top: '50% !important', left: '40vw !important' };

const MIN_SEARCH_LENGTH = 3;

class DashCam extends Component<Props, State> {
    lastViewedPage: string;
    currentlyLoadedPage: number;
    resetPage: boolean;
    autoRefreshTimer: any;
    searchTimer: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            appliedFiltersObj: {},
            selectedRecordingDashcamIMEI: '',
            isSearchOpen: false,
            searchKeyword: '',
            searchCalled: false,
            openRightDrawer: isDashcamAuthenticated(),
            showRefreshButton: false,
            isDashcamListUpdating: true,
            orgDevices: this.initOrgDevices(),
            selectedView: isDashcamAuthenticated() ? 'default' : '',
            events: [],
            isLoader: false,
            eventVideoPlayerEvent: {},
            isEventListError: false,
            filterEvents: [],
            isComparisonVisible: false,
            comparisionText: '',
            currentEventsCount: {},
            historyEventsCount: {},
            filterEventObj: this.initEventFilter('default'),
        };
        this.lastViewedPage = '';
        this.currentlyLoadedPage = 0;
        this.resetPage = false;
        this.autoRefreshTimer = undefined;
        this.searchTimer = null;
    }

    componentDidMount() {
        this.getOrgDevices();
        if (!isDashcamAuthenticated()) {
            getDashCamUserToken().then((response) => {
                if (response.status === 200) {
                    this.setState({ openRightDrawer: true, selectedView: 'default' }, () => {
                        setDashcamToken(response);
                        this.getOrgDevices();
                        this.eventTrackAnalytics(
                            DATE_FILTER_CHANGE,
                            this.state.filterEventObj.dateRange,
                        );
                    });
                } else this.showNotification(response.message);
            });
        } else {
            this.eventTrackAnalytics(
                DATE_FILTER_CHANGE,
                this.state.filterEventObj.dateRange,
            );
        }
    }

    initOrgDevices = () => ({
        data: [],
        currentPage: 0,
        totalPage: 0,
        count: 0,
        limit: 0,
        offset: 0,
    })

    initEventFilter = (selectedView: string) => ({
        dateRange: 'TODAY',
        dateFrom: moment().subtract(1, 'days'),
        dateTo: moment(),
        prevDateFrom: moment().subtract(1, 'days'),
        prevDateTo: moment(),
        isAllDashcam: (selectedView === 'default'),
    })

    getOrgDevices = (pageNo: number = 0, resetPage: boolean = false) => {
        const currentPage = (resetPage) ? 0 : pageNo;
        if (resetPage) this.currentlyLoadedPage = 0;
        this.startAutoRefreshTimer();

        const currentDashcamListData = resetPage ?
            this.initOrgDevices() : { ...this.state.orgDevices };

        if (isDashcamAuthenticated()) {
            let { searchKeyword } = this.state;
            searchKeyword = searchKeyword.trim();
            const searchKey = Number.isNaN(Number(searchKeyword)) ? 'name' : 'imei';
            getOrganizationDevices(searchKeyword, searchKey, pageNo).then((response) => {
                if (response.status === 200) {
                    this.setState({
                        orgDevices: {
                            ...response,
                            data: currentDashcamListData.data.concat(response.data),
                            currentPage,
                            totalPage: Math.ceil(response.count / DASHCAM_LIST_DEFAULT_ITEM_LIMIT),
                            count: response.count,
                        },
                        isDashcamListUpdating: false,
                    });
                } else {
                    this.setState(
                        { isDashcamListUpdating: false },
                        () => this.showNotification(response.message),
                    );
                }
            });
        }
    }

    showNotification = (message: string) => {
        this.props.appNotificationHandler([{
            ...snackbarNotificationObj,
            ...{
                isOpen: true,
                message,
            },
        }]);
    }

    updateDashcamView = (view: string, show: boolean) => {
        let { selectedView } = this.state;
        selectedView = show ? view : 'default';
        this.setState({ selectedView });
    }

    updateLastView = () => {
        this.lastViewedPage = this.state.selectedView;
    }

    historyBack = (refreshDashcamList: boolean = false) => {
        this.setState({
            selectedView: '',
        }, () => {
            this.updateLoader(true);
            const { filterEventObj } = this.state;
            this.setState({
                selectedView: this.lastViewedPage || 'default',
                filterEventObj: {
                    dateRange: filterEventObj.dateRange,
                    dateFrom: filterEventObj.dateFrom,
                    dateTo: filterEventObj.dateTo,
                    prevDateFrom: filterEventObj.prevDateFrom,
                    prevDateTo: filterEventObj.prevDateTo,
                    isAllDashcam: true,
                },
            }, () => this.updateLoader(false));
            this.lastViewedPage = '';
            if (refreshDashcamList) {
                this.resetListData();
            }
        });
    }

    showLiveView = (
        type: string,
        filtersObj: any,
    ) => filtersObj[type].some(d => d.cameraIds.length > 0);

    handleFilters = (
        type: string,
        filterValue: any,
        isInnerCamera: boolean = false,
        isRemove: boolean = false,
    ) => {
        const filtersObj = { ...this.state.appliedFiltersObj };
        switch (type) {
        case 'selectedDashcamsForLiveView': {
            if (this.state.selectedView !== 'isLiveView') this.updateLastView();
            if (filtersObj[type]) {
                if (isRemove) {
                    const dashIndex = filtersObj[type]
                        .findIndex(fItem => ((fItem.imei === filterValue.imei)));
                    if (dashIndex > -1) {
                        if (isInnerCamera) {
                            this.removeVideo(filterValue.imei, filterValue.cameraIds[0]);
                        } else filtersObj[type].splice(dashIndex, 1);
                    }
                } else {
                    const dashIndex = filtersObj[type]
                        .findIndex(fItem => ((fItem.imei === filterValue.imei)));
                    if (dashIndex > -1) {
                        filtersObj[type][dashIndex].cameraIds = [
                            ...filtersObj[type][dashIndex].cameraIds,
                            ...filterValue.cameraIds,
                        ];
                        filtersObj[type][dashIndex].name = filterValue.name;
                    } else filtersObj[type].push(filterValue);
                }
            } else {
                filtersObj[type] = [filterValue];
            }

            if (isInnerCamera || isRemove) {
                const showLiveView = this.showLiveView(type, filtersObj);
                this.updateDashcamView('isLiveView', showLiveView);
                if (!showLiveView) filtersObj[type] = [];
            }
            break;
        }
        case 'selectedDashcamsForEditView': {
            filtersObj[type] = filterValue;
            if (this.state.selectedView !== 'isEditView') this.updateLastView();
            this.updateDashcamView('isEditView', true);
            break;
        }
        case 'selectedDashcamForEventsView': {
            filtersObj[type] = filterValue;
            this.setState({
                selectedView: '',
            }, () => {
                this.updateLoader(true);
                this.setState({
                    filterEventObj: { ...this.state.filterEventObj, isAllDashcam: false },
                    selectedView: 'default',
                }, () => {
                    if (this.state.selectedView !== 'isEventsView') this.updateLastView();
                    this.updateDashcamView('isEventsView', true);
                });
            });
            filtersObj.selectedDashcamsForLiveView = [];
            break;
        }
        case 'selectedDashcamForRecordingsView':
            filtersObj.selectedDashcamsForLiveView = [];
            break;
        default:
            break;
        }

        this.setState({ appliedFiltersObj: filtersObj });
    }

    toggleCamSearch = (isSearchOpen: boolean = true) => {
        this.setState({ isSearchOpen, searchCalled: false }, () => {
            analytics.track(
                DASHCAM_ANALYTICS,
                {
                    eventName: SEARCH_DASHCAM,
                    feature: DASHCAM_ANALYTICS,
                    isSearchOpen,
                },
            );
        });
    }

    handleSearch = (e: Object, reset: boolean = false) => {
        let searchKeyword = '';
        let searchModified = this.state.searchCalled;

        if (!reset) {
            searchKeyword = e.target.value;
            if (searchKeyword.length >= MIN_SEARCH_LENGTH) searchModified = true;
        }

        this.setState({ searchKeyword, searchCalled: searchModified }, () => {
            if ((reset && searchModified) || (searchKeyword.length >= MIN_SEARCH_LENGTH)
                || (searchKeyword.length === 0 && searchModified)) {
                if (this.searchTimer) clearTimeout(this.searchTimer);
                this.searchTimer = setTimeout(() => {
                    this.resetListData();
                }, 500);
            }
            if (reset) this.toggleCamSearch(false);
        });
    }

    resetListData = () => {
        this.resetPage = true;
        this.currentlyLoadedPage = 0;
        this.setState(
            {
                isDashcamListUpdating: true,
                orgDevices: this.initOrgDevices(),
            },
            () => this.getOrgDevices(this.currentlyLoadedPage, true),
        );
    }

    loadNextPageDashcams = (pageNo: number) => {
        // block repeatation of dashcam API calls
        if (!this.resetPage && (this.currentlyLoadedPage !== pageNo)
            && (this.currentlyLoadedPage < pageNo)) {
            this.currentlyLoadedPage = pageNo;
            this.getOrgDevices(pageNo);
        } else if (this.resetPage) this.resetPage = false;
    }

    handleShowHideRefreshBtn = (showChip: boolean = false) => {
        if (showChip !== this.state.showRefreshButton) {
            this.setState({ showRefreshButton: showChip });
        }
    }

    startAutoRefreshTimer = () => {
        if (this.autoRefreshTimer) clearTimeout(this.autoRefreshTimer);

        this.autoRefreshTimer = setTimeout(
            () => this.handleShowHideRefreshBtn(true),
            DASHCAM_LIST_AUTO_REFRESH_TIME_IN_MS,
        );
    }

    refreshDashcamListData = (isRefresh: boolean = false) => {
        if (isRefresh) {
            analytics.track(
                DASHCAM_ANALYTICS,
                {
                    eventName: DASHCAM_REFRESH,
                    feature: DASHCAM_ANALYTICS,
                },
            );
            this.resetListData();
        } else this.startAutoRefreshTimer();
        this.handleShowHideRefreshBtn(false);
    }

    handleBookmarkClick = () => {
        // DO NOT REMOVE - for future use
    }

    setEventVideoPlayerEvent = (eventVideoPlayerEvent: any) =>
        this.setState({ eventVideoPlayerEvent });

    handleChange = name => (event) => {
        const input = event.target.value;
        this.setState({ [name]: input });
    };

    handleRecordingClick = (selectedDashcam: Object) => {
        if (selectedDashcam.status !== 'offline') this.setState({ selectedRecordingDashcamIMEI: selectedDashcam.imei, selectedView: 'RecordingView' });
        else this.showNotification('Recordings are not available as the camera is offline.');
    }

    updateDevice = (device: Object) => {
        const { orgDevices } = this.state;

        orgDevices.data = orgDevices.data.map((d) => {
            const newData = d;
            if (d.imei === device.imei) newData.name = device.name;

            return newData;
        });

        this.setState({ orgDevices });
    }

    deleteDashcam = () => this.resetListData();

    removeVideo = (imei: string, cameraId: string) => {
        const { appliedFiltersObj } = this.state;
        let { selectedView } = this.state;
        appliedFiltersObj.selectedDashcamsForLiveView =
            appliedFiltersObj.selectedDashcamsForLiveView.map((d) => {
                const device = d;
                if (device.imei === imei) {
                    device.cameraIds = device.cameraIds.filter(cId => cId !== cameraId);
                    return device;
                }
                return device;
            });
        if (!this.showLiveView('selectedDashcamsForLiveView', appliedFiltersObj)) {
            appliedFiltersObj.selectedDashcamsForLiveView = [];
            selectedView = 'default';
        }
        this.setState({
            appliedFiltersObj,
            selectedView,
        });
    }

    updateLoader = (isLoader: boolean) => {
        this.setState({ isLoader });
    }

    filteredAndSortedEvents = (events:any) => {
        const filteredEvents = events.filter(event =>
            SUPPORTED_EVENT_TYPES.includes(event.eventType));
        return filteredEvents.sort((a, b) => moment.utc(b.time) - moment.utc(a.time));
    };

    prepareEventsData = (res: any, startDate: any, isEventListError: boolean, filterObj) => {
        let currentEvents = [];
        let currentEventsCount = {};
        let historyEventsCount = {};
        if (res.length > 0) {
            const events = buildEventObject(res, startDate) || [];
            const currentEvent = events.currentEvent || [];
            currentEvents = this.filteredAndSortedEvents(currentEvent);
            currentEventsCount = events.currentEventCount;
            historyEventsCount = events.historyEventCount;
        }
        this.updateLoader(false);
        this.setState({
            events: currentEvents,
            isEventListError,
            currentEventsCount,
            historyEventsCount,
        }, () => {
            this.getFilterEvent(filterObj);
        });
    }

    getComparisonDates = (startDate: any, dateRange: string) => {
        let newStartDate = startDate;
        if (dateRange && startDate) {
            switch (dateRange.toUpperCase()) {
            case 'TODAY':
                newStartDate = getConvertedStartDate(moment().subtract(1, 'days'));
                this.setState({ isComparisonVisible: true, comparisionText: 'Since Yesterday' });
                break;
            case 'YESTERDAY':
                newStartDate = getConvertedStartDate(moment().subtract(2, 'days'));
                this.setState({ isComparisonVisible: true, comparisionText: 'Previous Day' });
                break;
            case 'LAST_7_DAYS':
                newStartDate = getConvertedStartDate(moment().subtract(14, 'days'));
                this.setState({ isComparisonVisible: true, comparisionText: 'Previous 7 Days' });
                break;
            default:
                this.setState({ isComparisonVisible: false, comparisionText: '' });
                break;
            }
        }
        return newStartDate;
    }

    getAllDashcamEvents = (startDateForHistory: any) => {
        this.updateLoader(true);
        const { startDate, endDate, filterObj } = this.state.filterEventObj;
        getAllDeviceForEvent().then((response: any) => {
            if (response.data) {
                const allDashcamImei = [];
                response.data.forEach(d =>
                    allDashcamImei.push({ imei: d.imei, name: d.name }));
                getEventByImei(allDashcamImei, startDateForHistory, endDate).then((res) => {
                    this.prepareEventsData(res, startDate, false, filterObj);
                });
            }
        });
    };

    getSingleDashcamEvents = (dashcam: any, newStartDate: any) => {
        this.updateLoader(true);
        const { startDate, endDate, filterObj } = this.state.filterEventObj;
        getDeviceEvents(dashcam.imei, newStartDate, endDate).then((response) => {
            if (response.status === 200) {
                const data = response.data.map(d => ({
                    ...d,
                    imei: dashcam.imei,
                    dashcamName: dashcam.name,
                }));
                this.prepareEventsData(data, startDate, false, filterObj);
            } else {
                this.prepareEventsData([], startDate, true, filterObj);
            }
        });
    }

    getDeviceEvents = () => {
        const dashcam = this.state.appliedFiltersObj.selectedDashcamForEventsView;
        const { dateRange, startDate, isAllDashcam } = this.state.filterEventObj;
        const newStartDate = this.getComparisonDates(startDate, dateRange);
        if (isAllDashcam) {
            this.getAllDashcamEvents(newStartDate);
        } else {
            this.getSingleDashcamEvents(dashcam, newStartDate);
        }
    };

    getFilterEvent = (filterEvent: any) => {
        let filterEvents = this.state.events;
        let selectedEvent = filterEvent.eventKey;
        if (filterEvent && selectedEvent) {
            if (HARD_CORNERING_EVENT_TYPES.includes(selectedEvent)) selectedEvent = HARD_CORNERING_EVENT_TYPES.join(', ');
            filterEvents = filterEvents.filter(event =>
                selectedEvent.includes(event.eventType));
        }
        const { filterEventObj } = this.state;
        this.setState({
            filterEvents,
            filterEventObj: { ...filterEventObj, filterObj: filterEvent },
        });
    };

    setFilterEventObj = (event) => {
        let { selectedView } = this.state;
        if (event.updateSelectedView) {
            selectedView = 'isEventsView';
        }
        this.setState({
            filterEventObj: {
                ...event,
            },
            selectedView,
        }, () => {
            this.getDeviceEvents();
        });
    };

    getDateRangeText = (selectedDateRange: string) => {
        let dateRange = selectedDateRange;
        switch (dateRange) {
        case 'TODAY': dateRange = 'Today';
            break;
        case 'YESTERDAY': dateRange = 'Yesterday';
            break;
        case 'LAST_7_DAYS': dateRange = 'Last 7 Days';
            break;
        case 'LAST_30_DAYS': dateRange = 'Last 30 Days';
            break;
        case 'CUSTOM': dateRange = 'Custom';
            break;
        default: break;
        }
        return dateRange;
    }

    getEventName = () => {
        let eventName = '';
        const { filterEventObj, selectedView } = this.state;
        if (filterEventObj.isAllDashcam && selectedView === 'default') {
            eventName = EVENT_SUMMARY_DEFAULT;
        } else if (filterEventObj.isAllDashcam && selectedView ===
            'isEventsView') {
            eventName = EVENT_SUMMARY_ALL_DASHCAM;
        } else {
            eventName = EVENT_SUMMARY_DASHCAM;
        }
        return eventName;
    }

    eventTrackAnalytics = (eventHandler: string, dateRange: string = DATE_RANGE, eventKey: string = '', eventType: string = '') => {
        const { filterEventObj } = this.state;
        let newObjForTrackAnalytics = {
            eventName: this.getEventName(),
            selectedDate: this.getDateRangeText(dateRange === DATE_RANGE ?
                filterEventObj.dateRange : dateRange),
            selectedFilter: !isEmpty(filterEventObj.filterObj) ?
                EVENT_TYPE_MAPPING[filterEventObj.filterObj.eventKey].name : NONE,
            selectedEventType: NONE,
        };
        switch (eventHandler) {
        case GET_ALL_EVENTS:
            newObjForTrackAnalytics.eventName =
                filterEventObj.isAllDashcam ?
                    EVENT_SUMMARY_ALL_DASHCAM : EVENT_SUMMARY_DASHCAM;
            newObjForTrackAnalytics.selectedFilter = NONE;
            break;
        case DATE_FILTER_CHANGE:
            newObjForTrackAnalytics.selectedDate = this.getDateRangeText(dateRange);
            break;
        case CARD_FILTER_CHANGE:
            newObjForTrackAnalytics.selectedFilter =
                EVENT_TYPE_MAPPING[eventKey].name;
            break;
        case DASHCAM_EVENT_ICON_CLICK:
            newObjForTrackAnalytics.eventName = EVENT_SUMMARY_DASHCAM;
            break;
        case DASHCAM_EVENT_VIEW:
            newObjForTrackAnalytics.selectedEventType = eventType;
            break;
        case BACK_TO_EVENT_DEFAULT_PAGE:
            newObjForTrackAnalytics.eventName = EVENT_SUMMARY_DEFAULT;
            break;
        case EVENT_SETTINGS:
            newObjForTrackAnalytics = {
                eventName: DASHCAM_EVENT_SETTING,
                feature: DASHCAM_ANALYTICS,
            };
            break;
        default: break;
        }
        analytics.track(
            DASHCAM_ANALYTICS,
            { ...newObjForTrackAnalytics },
        );
    }

    render() {
        const {
            isSearchOpen,
            orgDevices,
            appliedFiltersObj,
            selectedRecordingDashcamIMEI,
            isLoader,
            selectedView,
            eventVideoPlayerEvent,
            isComparisonVisible,
            comparisionText,
            currentEventsCount,
            historyEventsCount,
            filterEventObj,
        } = this.state;
        const subHeader = `Showing ${orgDevices.data.length} of ${orgDevices.count}`;
        let selectedDeviceData = {};

        const showEventsView = (selectedView === 'isEventsView' || selectedView === 'default');
        let { selectedDashcamForEventsView } = appliedFiltersObj;
        if (showEventsView && eventVideoPlayerEvent && eventVideoPlayerEvent.imei) {
            selectedDashcamForEventsView =
            { name: eventVideoPlayerEvent.dashcamName, imei: eventVideoPlayerEvent.imei };
        }

        if (selectedView === 'RecordingView' && selectedRecordingDashcamIMEI) {
            if (orgDevices && orgDevices.data && orgDevices.data.length > 0) {
                const index = orgDevices.data.findIndex(cItem =>
                    ((cItem.imei === selectedRecordingDashcamIMEI)));
                selectedDeviceData = { ...orgDevices.data[index] };
            }
        }

        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {selectedView === 'RecordingView' && selectedRecordingDashcamIMEI &&
                    <CameraRecordings
                        selectedDevice={selectedDeviceData}
                        imei={selectedRecordingDashcamIMEI}
                        showNotification={this.showNotification}
                        updateLoader={this.updateLoader}
                    />
                }
                {isLoader && <AppLoader type="fullScreen" loaderStyle={loaderStyle} />}
                {selectedView === 'isLiveView' &&
                    <LiveVideos
                        selectedDevice={appliedFiltersObj.selectedDashcamsForLiveView}
                        removeVideo={(imei, cameraId) => this.removeVideo(imei, cameraId)}
                        showNotification={this.showNotification}
                        updateLoader={this.updateLoader}
                    />
                }
                {selectedView === 'isEditView' &&
                    <EditDashcam
                        selectedDevice={appliedFiltersObj.selectedDashcamsForEditView}
                        showNotification={this.showNotification}
                        updateDevice={this.updateDevice}
                        deleteDashcam={this.deleteDashcam}
                        historyBack={(flag: boolean = false) => this.historyBack(flag)}
                        updateLoader={this.updateLoader}
                    />
                }
                {(showEventsView && isEmpty(eventVideoPlayerEvent)) &&
                    <EventSummary
                        dashcam={appliedFiltersObj.selectedDashcamForEventsView}
                        updateLoader={this.updateLoader}
                        showNotification={this.showNotification}
                        getFilterEvent={this.getFilterEvent}
                        getAllDashcamEvents={this.getAllDashcamEvents}
                        isComparisonVisible={isComparisonVisible}
                        comparisionText={comparisionText}
                        currentEventsCount={currentEventsCount}
                        historyEventsCount={historyEventsCount}
                        setFilterEventObj={this.setFilterEventObj}
                        filterEventObj={filterEventObj}
                        trackAnalytics={this.eventTrackAnalytics}
                    />
                }
                {showEventsView && !isEmpty(eventVideoPlayerEvent) &&
                    <EventVideo
                        event={eventVideoPlayerEvent}
                        dashcam={selectedDashcamForEventsView}
                        updateLoader={this.updateLoader}
                        setEventVideoPlayerEvent={this.setEventVideoPlayerEvent}
                        showNotification={this.showNotification}
                    />
                }
                {this.state.openRightDrawer &&
                    <RightDrawer showDrawer>
                        <div className={customStyles.container}>
                            {(selectedView !== 'isEventsView' && isEmpty(filterEventObj.filterObj)) &&
                                <React.Fragment>
                                    <Card
                                        raised={false}
                                        className={customStyles.dashcam_list_header_card}
                                    >
                                        <CardContent className={customStyles.card_content}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ flex: 'auto' }}>
                                                    {!isSearchOpen &&
                                                        <React.Fragment>
                                                            <Typography variant="h5" className={customStyles.dashcam_list_header_title} component="h2">
                                                                <span>Dashcams</span>
                                                            </Typography>
                                                            <div className={customStyles
                                                                .drawer_header_btn_container}
                                                            >
                                                                <Tooltip title="Search" disableFocusListener>
                                                                    <GetSvgIcon
                                                                        type="dashcamSearch"
                                                                        fillcolor="#fff"
                                                                        onClick={() => this
                                                                            .toggleCamSearch(true)}
                                                                    />
                                                                </Tooltip>
                                                                {/* DO NOT REMOVE
                                                                 - for future use */}
                                                                {/* <Tooltip
                                                                    title="Bookmarks"
                                                                    disableFocusListener
                                                                >
                                                                    <GetSvgIcon
                                                                        type="dashcamBookmark"
                                                                        fillcolor="#fff"
                                                                        onClick={() => this
                                                                            .handleBookmarkClick()
                                                                        }
                                                                    />
                                                                </Tooltip> */}
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                    {isSearchOpen &&
                                                        <div className={customStyles
                                                            .search_box_container}
                                                        >
                                                            <div className={customStyles
                                                                .search_box}
                                                            >
                                                                <SearchBox
                                                                    sx={{ '& input': { letterSpacing: 0, padding: '6px 0px 7px' } }}
                                                                    autoFocus
                                                                    placeholder="Search for a Dashcam"
                                                                    title="Please enter at least 3 characters"
                                                                    className={customStyles
                                                                        .dashcam_search}
                                                                    onChange={e =>
                                                                        this.handleSearch(e)}
                                                                    fullWidth
                                                                    value={this.state.searchKeyword}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <i className={
                                                                                    customStyles
                                                                                        .search_icon
                                                                                }
                                                                                />
                                                                            </InputAdornment>
                                                                        ),
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <i className={customStyles.cancel_search} onClick={() => this.handleSearch({}, true)} onKeyDown={() => { }} role="button" tabIndex="-1" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    fieldtype="TextField"
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <Typography className={customStyles.dashcam_list_subheader_title} paragraph component="p">
                                                        {subHeader}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                    <DashcamList
                                        devices={orgDevices}
                                        showRefreshButton={this.state.showRefreshButton}
                                        refreshDashcamListData={this.refreshDashcamListData}
                                        isUpdating={this.state.isDashcamListUpdating}
                                        handleFilters={{
                                            updateFilters: this.handleFilters,
                                            appliedFilters: appliedFiltersObj,
                                        }}
                                        loadNextPage={this.loadNextPageDashcams}
                                        redirectTo={() => { }}
                                        handleRecordingClick={this.handleRecordingClick}
                                        trackAnalytics={this.eventTrackAnalytics}
                                        showLoader={this.updateLoader}
                                    />
                                </React.Fragment>
                            }
                            {(selectedView === 'isEventsView' || !isEmpty(filterEventObj.filterObj)) &&
                                <EventList
                                    handleBackButtonClick={this.historyBack}
                                    dashcam={appliedFiltersObj.selectedDashcamForEventsView}
                                    events={this.state.filterEvents}
                                    setEventVideoPlayerEvent={this.setEventVideoPlayerEvent}
                                    isEventListError={this.state.isEventListError}
                                    isAllDashcam={filterEventObj.isAllDashcam}
                                    selectedFilter={filterEventObj.filterObj}
                                    getFilterEvent={this.getFilterEvent}
                                    trackAnalytics={this.eventTrackAnalytics}
                                />
                            }
                        </div>
                    </RightDrawer>
                }
            </MiniDrawer >
        );
    }
}

export default reduxConnect(DashCam, { ...notificationActions });
