/* @flow */
import React, { Component } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.assets';
import MapsWrapper from '../MapsWrapper';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import styles from './Assets.module.scss';
import { getParamValue, isEmpty, getSnapRoute, setTripData, getTripData, getLocalStorageValue, getData } from '../../helper-classes/utility-functions';
import AppLoader from '../../components/AppLoader';
import TripsList from '../../components/Trips/TripsList';
import TripsDetail from '../../containers/Trips/TripsDetail';
import AssetDetails from '../../components/AssetDetails/AssetDetails';
import { getConvertedStartDate, getConvertedEndDate, getAssetIconFromType, getImage, getAssetColorFromStatus } from '../../util/trip_util';
import { processLandmarkMarkers } from '../Landmark/LandmarkDetails/epics.landmarks';
import { FETCH_ASSETS_LIMIT, FETCH_TRIPS_LIMIT, EVENT_HISTORY } from './constants.assets';
import RightDrawer from '../../components/SharedComponents/RightDrawer/RightDrawer';
import analytics from '../../analytics/index';
import PrintEventsList from '../../components/Print/PrintEventsList';
import config from './../../constants/Config';

const defaultDrawerWidth = 388;
const IGNITION_ON = 'IGN_ON';
const IGNITION_OFF = 'IGN_OFF';
const MOVEMENT_START = 'MOVE_START';
const MOVEMENT_STOP = 'MOVE_STOP';

export type Props = {
    fetchAssetsDetails: Function,
    assetsDetails: Object,
    isLoader: boolean,
    fetchTrips: Function,
    trips: Object,
    history: {
        push: Function,
        action: string,
    },
    location: {
        key: string,
        pathname: string,
    },
    isEventLoader: boolean,
    match: Object,
    isTripLoader: boolean,
    updateTripsLoader: Function,
    events: Object,
    fetchAssetsEvents: Function,
    isTripsFetchError: boolean,
    updateMapRefreshLoader: Function,
    mapRefreshLoader: boolean,
};

export type State = {
    trips: Object,
    tripFilterChange: boolean,
    pageNumber: number,
    rowsPerPage: number,
    loadMoreTrips: boolean,
    activeTab: string,
    fromDate: any,
    toDate: any,
    fromDateEvent: any,
    toDateEvent: any,
    assetDetails: Object,
    events: any,
    eventsPage: number,
    initEvents: boolean,
    initTrips: boolean,
    loadMoreEvents: boolean,
    tripsLimit: number,
    tripsPage: number,
    showLoction: Object,
    drawerWidth: number,
    tripDetailIndex: number,
    tripView: boolean,
    dateSelected: string,
    recentUpdatedTrip: string,
    mapEvents:Array<Object>,
    zoomEvent: Object,
    mapStartPoint: Object,
    isRightDrawerOpen: boolean,
    datePicked: Date,
    selectedTripsCount: number,
    snapRoutes: string,
    mapTrip: any,
    polylineData: any,
    infoWindowEvent: Object,
    landmarks: any,
    loadLandmarks: boolean,
    hideLandmarks: boolean,
};

const MAX_WAYPOINTS_PER_REQUEST = 23;
const TRIP_COLORS = ['#0000FF', '#007AFF', '#d807eb', '#c41451', '#7502e8', '#9b61d4'];


class Assets extends Component<Props, State> {
    height: number;
    isNearestLandmark: boolean;
    assetId: string;
    zoomLevel: number;
    mapCenter: any;
    mapDirectionsRetryDelayFactor: number;
    handleAssetTripsBack: {
        comingFromTrips: boolean,
        locationKey: string,
    };
    tripStartEvent: string;
    tripStopEvent: string;
    mapDirections: any;
    mapPageEventCallback: any;
    map: Object;

    constructor(props: Props) {
        super(props);
        this.state = this.getInitialStateObject();
        this.handleAssetTripsBack = {
            comingFromTrips: false,
            locationKey: '',
        };
        this.mapDirections = [];
        this.mapPageEventCallback = null;
        this.tripStartEvent = IGNITION_ON;
        this.tripStopEvent = IGNITION_OFF;
    }

    componentDidMount() {
        this.props.fetchAssetsDetails(getParamValue(this.props, 'id'));
        this.assetId = getParamValue(this.props, 'id');
        if (this.props.match.params.isPrint === 'print') {
            const { fromDate, toDate } = this.props.match.params;
            let limit = 2500;
            if (!fromDate || !toDate) {
                limit = 50;
            }
            // Print
            const queryParam = queryString.parse(window.location.search);
            const dateSelected = (
                queryParam.dateSelected ? queryParam.dateSelected : this.state.dateSelected
            );
            this.applyFilter(fromDate, toDate, limit, dateSelected);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params) {
            this.setState({
                activeTab: nextProps.match.params.activeTab,
            });
            const { fromDate, toDate } = nextProps.match.params;
            if (fromDate && toDate) {
                if (fromDate !== this.state.fromDate
                    && toDate !== this.state.toDate) {
                    this.setState({ fromDate, toDate });
                }
            }
        }

        if (this.props.history.action === 'PUSH') {
            if (this.handleAssetTripsBack.locationKey !== ''
                && (this.handleAssetTripsBack.locationKey !== this.props.location.key)) {
                this.handleAssetTripsBack.comingFromTrips = true;
            }
            this.handleAssetTripsBack.locationKey = this.props.location.key;
        }

        if (this.props.history.action === 'POP' && this.handleAssetTripsBack.comingFromTrips) {
            this.props.fetchAssetsDetails(getParamValue(this.props, 'id'));
            this.handleAssetTripsBack.comingFromTrips = false;
        }

        const newState = { ...this.state };
        let needToUpdateState = false;
        if (this.state.initTrips) {
            const { trips } = nextProps;
            if (trips && trips.trips &&
                Array.isArray(trips.trips) &&
                trips !== this.props.trips) {
                newState.trips = this.updateTripColorAndDefultConfig(trips);
                newState.loadMoreTrips = false;
                newState.recentUpdatedTrip = '';
                this.mapDirections = [];
                needToUpdateState = true;
            }
        }

        if (needToUpdateState) {
            this.setState({ ...newState });
            if (newState.trips && newState.trips.trips && newState.trips.tripEvents) {
                let loadAllTrips = true;
                if (newState.tripDetailIndex !== -1) {
                    const filteredTrips = newState.trips.trips.filter(trip => trip.type === 'COMPLETED' || trip.type === 'INPROCESS');
                    if (filteredTrips.length > newState.tripDetailIndex) {
                        this.setTripStartEnd(newState.trips.tripEvents);
                        const trip = filteredTrips[newState.tripDetailIndex];
                        const events = newState.trips.tripEvents.content.filter(event =>
                            trip.id === event.tripId);
                        this.loadMapDirections([trip], events);
                        loadAllTrips = false;
                    }
                }
                if (loadAllTrips) {
                    const filteredTrips = newState.trips.trips.filter(trip => trip.type !== 'STOPPED');
                    this.setTripStartEnd(newState.trips.tripEvents);
                    this.loadMapDirections(filteredTrips, newState.trips.tripEvents.content);
                    this.props.updateTripsLoader(false);
                }
            }
        }

        if (nextProps.assetsDetails
            && this.props.assetsDetails !== nextProps.assetsDetails) {
            this.setState({
                assetDetails: nextProps.assetsDetails,
            });
        }
    }

    componentDidUpdate() {
        const id = getParamValue(this.props, 'id');
        if (id && id !== this.assetId) {
            this.resetTripsDetailIndex(id);
        }
    }

    updateTripColorAndDefultConfig = (response) => {
        const trips = response;
        const filteredTripsId = trips.trips.filter(trip => trip.type !== 'STOPPED').map(t => t.id) || [];
        const isEventAvilable = (trips.tripEvents && trips.tripEvents.content);
        let events = isEventAvilable ? trips.tripEvents.content || [] : [];
        trips.trips = trips.trips.map((t) => {
            const newTrip = t;
            if (t.type !== 'STOPPED' && filteredTripsId.length > 0) {
                const index = filteredTripsId.indexOf(t.id);
                newTrip.directionOptions = {
                    polylineOptions: {
                        strokeColor: this.getTripColor(index),
                        strokeWeight: 3,
                        strokeOpacity: 0.4,
                        zIndex: 1,
                    },
                    suppressMarkers: true,
                    // zoom is set later in MapsWrapper.zooomToMarkes() function
                    preserveViewport: true,
                };
                events = events.map((e) => {
                    if (e.tripId === t.id) {
                        const newEvent = e;
                        newEvent.tripColor = newTrip.directionOptions.polylineOptions.strokeColor;
                        newEvent.strokeOpacity =
                            newTrip.directionOptions.polylineOptions.strokeOpacity;
                        newEvent.tripIndex = filteredTripsId.length - index;
                        return newEvent;
                    }
                    return e;
                });
            }
            return newTrip;
        });
        if (isEventAvilable) trips.tripEvents.content = events;
        return trips;
    }

    getInitialStateObject = () => ({
        trips: {},
        tripFilterChange: false,
        pageNumber: 0,
        rowsPerPage: FETCH_ASSETS_LIMIT,
        loadMoreTrips: true,
        activeTab: '',
        events: [],
        eventsPage: 0,
        tripsLimit: FETCH_TRIPS_LIMIT,
        tripsPage: 0,
        loadMoreEvents: true,
        fromDate: getConvertedStartDate(Date.now()),
        toDate: getConvertedEndDate(Date.now()),
        fromDateEvent: getConvertedStartDate(moment().startOf('day')),
        toDateEvent: getConvertedEndDate(moment().endOf('day')),
        assetDetails: {},
        initEvents: true,
        initTrips: true,
        showLoction: {},
        drawerWidth: 700,
        tripDetailIndex: -1,
        tripView: false,
        dateSelected: 'TODAY',
        recentUpdatedTrip: '',
        mapEvents: [],
        mapTrip: [],
        zoomEvent: {},
        mapStartPoint: {},
        isRightDrawerOpen: true,
        datePicked: new Date(),
        selectedTripsCount: 0,
        snapRoutes: getSnapRoute(),
        polylineData: [],
        infoWindowEvent: {},
        landmarks: [],
        loadLandmarks: true,
        hideLandmarks: false,
    });

    resetTripsDetailIndex = (id) => {
        this.setState(this.getInitialStateObject(), () => {
            this.props.fetchAssetsDetails(getParamValue(this.props, 'id'));
            this.assetId = id;
        });
    };

    setTripsDetailIndex = (tripDetailIndex) => {
        this.setState({ tripDetailIndex }, () => {
            this.tripDetailsEventsTrack('CHANGE_TRIP_DETAIL_INDEX');
        });
    };


    tripDetailsEventsTrack = (eventTitle) => {
        const trackObj = {
            tripsSelection: 'SingleTrip',
            dateRange: this.state.dateSelected === 'LAST50' ? 'LAST 50' : this.state.dateSelected,
            feature: EVENT_HISTORY,
            eventName: 'Trips Details',
            mapAutoRefresh: getLocalStorageValue('mapAutoRefresh') || false,
            mapTrafficLayer: getLocalStorageValue('mapTrafficLayer') || false,
            snapRoutes: getLocalStorageValue('snapRoutes') || false,
        };
        analytics.track(eventTitle, trackObj);
    }

    refreshData = (zoomLevel, mapCenter) => {
        if (this.state.activeTab && this.state.activeTab === 'trips') {
            if (this.state.dateSelected === 'TODAY') {
                // no need to reset the zoom level and map center here
                // as it is handled later when new events data is rendered
                this.handleRefresh(this.state.fromDate, this.state.toDate, false);
                this.props.updateMapRefreshLoader(true);
            }
        } else {
            // this block is never executed, remove after confirmation
            this.zoomLevel = zoomLevel;
            this.mapCenter = mapCenter;
            this.props.fetchAssetsDetails(this.assetId, false);
        }
    }

    updateDrawerWidth = (width: number) => {
        this.setState({ drawerWidth: width });
    }

    setDatePicked = (datePicked) => {
        this.setState({ datePicked });
    };

    setDateSelected = (dateSelected) => {
        this.setState({ dateSelected });
    };

    /**
     * Function to get trips. This function calling the fetchTrips method and it will
     * return the trips list api response.
     */
    loadTrips = (isDate, init) => {
        const offset = this.state.pageNumber * this.state.rowsPerPage; // calculating offset
        if (this.state.loadMoreTrips) {
            this.props.updateTripsLoader(true);
            this.setState({
                initTrips: true,
                initEvents: init,
            }); // setting next page
            const paramsUrl =
                this.getParamsUrl(offset, this.state.fromDate, this.state.toDate, isDate);
            this.props.fetchTrips(getParamValue(this.props, 'id'), paramsUrl, true);
        }
    }

    reInitTrips = () => {
        this.setState({
            tripsPage: 0,
            pageNumber: 0,
            trips: {},
            initTrips: true,
            loadMoreTrips: true,
            loadLandmarks: true,
        }, () => {
            this.props.updateTripsLoader(true);
            const paramsUrl =
                this.getParamsUrl(0, this.state.fromDate, this.state.toDate, true);
            this.props.fetchTrips(getParamValue(this.props, 'id'), paramsUrl);
        });
    }

    applyFilter = (fromDate, toDate, tripsLimit, dateSelected) => {
        this.setState({
            fromDate, toDate, tripsLimit, dateSelected,
        });
        this.reInitTrips();
    }

    handleRefresh = (fromDate, toDate, isSetZoomEvent = true) => {
        if (isSetZoomEvent) this.setZoomEvent();
        const limit = !fromDate && !toDate ? 50 : FETCH_TRIPS_LIMIT;
        this.applyFilter(fromDate, toDate, limit, this.state.dateSelected);
    };

    getParamsUrl = (offset, fromDate, toDate, isDate) => {
        let params = `?limit=${this.state.tripsLimit}&offset=${offset}&dateFilter=${this.state.dateSelected}`;
        if (isDate && fromDate && toDate) {
            params = `${params}&startDate=${fromDate}.000Z&endDate=${toDate}.000Z`;
        } else {
            params = `${params}&mostRecent=true`;
        }
        return params;
    }

    /**
     * Setting ative tab
     */
    setActiveTab = (tab) => {
        this.props.history.push(`/assets/${getParamValue(this.props, 'id')}/${tab}`);
        this.setState({ activeTab: tab, showLoction: {} });
    }

    redirectTo = (action) => {
        this.props.history.push(action);
    }

    showOnMap = (location) => {
        this.setState({ showLoction: location });
    }

    getIcons = () => {
        const { showLoction, activeTab } = this.state;
        const { assetsDetails } = this.props;
        let icons = [];
        if (assetsDetails && assetsDetails.assetId && assetsDetails.lat && typeof assetsDetails.lat === 'number') {
            icons = [{
                lat: Number(assetsDetails.lat),
                lng: Number(assetsDetails.lng),
                color: getAssetColorFromStatus(assetsDetails),
                image: { ...getAssetIconFromType(assetsDetails.assetType) },
            }];
        }
        if (showLoction && Object.keys(showLoction).length > 0 && activeTab === 'trips') {
            icons.push({
                lat: showLoction.lat || 0,
                lng: showLoction.lng || 0,
                color: '#007aff',
                image: { image: getImage('calendarIcon'), style: { width: 14, top: -26 } },
            });
        }
        return icons;
    }

    loadMapDirectionsFromTrips = (selectedTripIds) => {
        this.setState({ selectedTripsCount: selectedTripIds.length });
        let selectedTrips = this.state.trips &&
            this.state.trips.trips ? this.state.trips.trips : [];
        let events = this.state.trips && this.state.trips.tripEvents &&
            this.state.trips.tripEvents.content ?
            this.state.trips.tripEvents.content : [];
        if (selectedTripIds && selectedTripIds.length > 0) {
            selectedTrips = selectedTrips.filter(trip =>
                selectedTripIds.includes(trip.id));
            events = events.filter(event =>
                selectedTripIds.includes(event.tripId));
        }
        this.loadMapDirections(selectedTrips, events);
    }

    loadMapDirections = (inputTrips, inputTripsEvents) => {
        const events = (inputTripsEvents && Array.isArray(inputTripsEvents)) ?
            inputTripsEvents.filter(event =>
                (event.locationSegment && event.locationSegment.lat && event.locationSegment.lng)
            && event.tripId != null) : [];

        /* reverse the order as events are sorted by eventDate in descending order,
        to populate start and end points correctly
        and to populate the way points in chronological order for the direction service api call */
        events.reverse();
        const trips = inputTrips ? inputTrips.filter(trip => trip.type !== 'STOPPED') : [];
        const mapStartPoint = trips.length === 1 ? trips[0].startLocation : {};
        this.setState({
            mapEvents: events,
            mapTrip: trips,
            mapStartPoint,
        });
        const maxEventsPerBatch = MAX_WAYPOINTS_PER_REQUEST + 1;
        this.mapDirections = [];
        if (window.google && this.state.snapRoutes === 'true') {
            const { google } = window;
            this.mapDirections = Array(trips.length).fill({});
            const DirectionsService = new google.maps.DirectionsService();
            this.mapDirectionsRetryDelayFactor = 0;
            trips.forEach((trip, index) => {
                const tripEvents = events.filter(event => event.tripId === trip.id);
                const batchedEvents = [];
                /* Divide route to several parts because max waypoint limit is 25
                   (23 waypoints + 1 origin + 1 destination) */
                for (let i = 0; i < tripEvents.length; i += maxEventsPerBatch) {
                    batchedEvents.push(tripEvents.slice(i, i + maxEventsPerBatch + 1));
                }
                this.mapDirections
                    .splice(index, 1, {
                        tripId: trip.id,
                        batchCount: batchedEvents.length,
                        result: Array(batchedEvents.length).fill({}),
                    });
                batchedEvents.forEach((batchEvent, batchIndex) => {
                    const wayPoints = [];
                    const startPoint = batchEvent[0];
                    const endPoint = batchEvent[batchEvent.length - 1];
                    // Waypoints do not include startPoint (origin) and endPoint (destination)
                    for (let j = 1; j < batchEvent.length - 1; j += 1) {
                        const event = batchEvent[j];
                        const latLng = new google.maps.LatLng(
                            event.locationSegment.lat,
                            event.locationSegment.lng,
                        );
                        const address = {
                            location: latLng,
                            stopover: false,
                        };
                        wayPoints.push(address);
                    }

                    this.getDirections(
                        startPoint,
                        endPoint,
                        wayPoints,
                        trip.directionOptions,
                        DirectionsService,
                        google,
                        {
                            batchIndex,
                            tripId: startPoint.tripId,
                            tripStatus: trip.type,
                        },
                    );
                }); // batchEvents loop
            }); // trips loop
        }
        const polylineData = [];
        trips.forEach(t =>
            polylineData.push({ ...t, events: events.filter(e => e.tripId === t.id) }));
        this.setState({ polylineData });
        setTimeout(() => {
            // add small delay to avoid race condition and to get correct map bounds
            this.getLandmarks(inputTrips);
        }, 1000);
    }

    /**
     * spread operator is used to clone the google object value
     * to avoid unwanted value update via google direction render
     * each render is updating ${step_interpolation}
     * this fixes the straight line issue in routes
    */

    cloneGoogleDirectionRes = data => ({
        ...data,
        routes: data.routes.map(r => ({
            ...r,
            legs: r.legs.map(l => ({
                ...l,
                via_waypoint:
                    l.via_waypoint.map(w =>
                        ({
                            ...w,
                            step_interpolation: w.step_interpolation,
                        })),
            })),
        })),
    })

    getDirections = (
        startPoint,
        endPoint,
        wayPoints,
        directionOptions,
        DirectionsService,
        google,
        info,
    ) => {
        if (this.state.snapRoutes !== 'true') return;
        const localData = getTripData(`${info.tripId}-${info.batchIndex}`);
        let recentUpdatedTrip = '';
        if (localData) {
            this.mapDirections.some((t) => {
                if (t.tripId === info.tripId) {
                    t.result.splice(info.batchIndex, 1, localData);
                    if (t.result.filter(r => isEmpty(r)).length === 0) {
                        recentUpdatedTrip = t.tripId;
                    }
                    return true;
                }
                return false;
            });
            if (recentUpdatedTrip) {
                this.setState({
                    recentUpdatedTrip,
                });
            }
            return;
        }
        DirectionsService.route({
            origin: new google.maps.LatLng(
                startPoint.locationSegment.lat,
                startPoint.locationSegment.lng,
            ),
            destination: new google.maps.LatLng(
                endPoint.locationSegment.lat,
                endPoint.locationSegment.lng,
            ),
            travelMode: google.maps.TravelMode.DRIVING,
            optimizeWaypoints: false, // need to used the actual order of events
            waypoints: wayPoints,
        }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                const resultWithOptions = result;
                resultWithOptions.pwaMapRenderOptions = directionOptions;
                this.mapDirections.some((t) => {
                    if (t.tripId === info.tripId) {
                        t.result.splice(info.batchIndex, 1, resultWithOptions);
                        if (info.tripStatus !== 'INPROCESS') {
                            // wayPoints.length + 2 is coz we have start and end event also
                            setTripData(`${info.tripId}-${info.batchIndex}`, resultWithOptions, wayPoints.length + 2);
                        }
                        if (t.result.filter(r => isEmpty(r)).length === 0) {
                            recentUpdatedTrip = t.tripId;
                        }
                        return true;
                    }
                    return false;
                });
                if (this.state.snapRoutes === 'true' && recentUpdatedTrip) {
                    this.setState({
                        recentUpdatedTrip,
                    });
                }
            } else if (status === google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
                /*  - Add a delay of 1 second for each next directions request.
                    - After the initial allocated quota of requests (which is 10 currently) is used,
                      the API enforces rate limits on additional requests on a per-second basis.
                    - It means after 10 initial requests we can execute only 1 request per second.
                    - If too many requests are made within a certain time period,
                      the API returns an OVER_QUERY_LIMIT response code.
                    - https://developers.google.com/maps/documentation/javascript/directions */
                this.mapDirectionsRetryDelayFactor += 1;
                setTimeout(() => {
                    this.getDirections(
                        startPoint,
                        endPoint,
                        wayPoints,
                        directionOptions,
                        DirectionsService,
                        google,
                        info,
                    );
                }, (this.mapDirectionsRetryDelayFactor * 1000));
            }
        });
    }

    getTripColor = (tripNum) => {
        let tripColor;
        if (tripNum < TRIP_COLORS.length) {
            tripColor = TRIP_COLORS[tripNum];
        } else {
            const tripIdx = tripNum % TRIP_COLORS.length;
            tripColor = TRIP_COLORS[tripIdx];
        }
        return tripColor;
    }

    goToTripDetail = (index, date) => {
        this.setState({ tripDetailIndex: index, dateSelected: date });
    };

    returnToTripsView = () => {
        this.setZoomEvent();
        this.setState({
            tripDetailIndex: -1,
            loadMoreTrips: true,
            tripView: true,
            drawerWidth: 568,
            selectedTripsCount: 0,
        });
    };

    setZoomEvent = (zoomEventId) => {
        if (zoomEventId) {
            const zoomEvent = (this.state.trips && this.state.trips.tripEvents.content) ?
                this.state.trips.tripEvents.content.find(event =>
                    event.id === zoomEventId) : {};
            this.setState({ zoomEvent, infoWindowEvent: {} });
        } else {
            this.setState({ zoomEvent: {}, infoWindowEvent: {} });
        }
    };

    toggleRightDrawer = (isRightDrawerOpen) => {
        this.setState({ isRightDrawerOpen });
    };

    printPage = () => {
        let url = `${window.location.origin}/assets/${this.assetId}/trips/print`;
        if (this.state.fromDate && this.state.toDate) {
            url = url.concat(`/${this.state.fromDate}/${this.state.toDate}?dateSelected=${this.state.dateSelected}`);
        } else {
            url = url.concat(`?dateSelected=${this.state.dateSelected}`);
        }
        window.open(url, '_blank');
    };

    handleRefreshTripDetail = (fromDate, toDate) => {
        this.handleRefresh(fromDate, toDate);
        this.tripDetailsEventsTrack('REFRESH');
    }

    renderRightDrawer = (assetsDetails, isTripLoader, isEventLoader) => {
        if (this.state.tripDetailIndex > -1) {
            return (<TripsDetail
                trips={this.state.trips}
                assetsDetail={assetsDetails}
                tripDetailIndex={this.state.tripDetailIndex}
                returnToTripsView={this.returnToTripsView}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                handleRefresh={this.handleRefreshTripDetail}
                loadMapDirectionsFromTrips={this.loadMapDirectionsFromTrips}
                setDrawerWidth={this.updateDrawerWidth}
                setZoomEvent={this.setZoomEvent}
                zoomEvent={this.state.zoomEvent}
                setTripDetailIndex={this.setTripsDetailIndex}
            />);
        } else if (this.state.activeTab && this.state.activeTab === 'trips' && !this.props.isLoader) {
            return (<TripsList
                loadTrips={this.loadTrips}
                reInitTrips={this.reInitTrips}
                trips={this.state.trips}
                showOnMap={this.showOnMap}
                events={this.state.events}
                setDrawerWidth={this.updateDrawerWidth}
                assetName={this.state.assetDetails.assetName}
                isTripLoader={isTripLoader}
                applyFilter={this.applyFilter}
                history={this.props.history}
                fromDate={this.state.fromDate}
                toDate={this.state.toDate}
                isEventLoader={isEventLoader}
                assetId={this.assetId}
                serialNumber={this.state.assetDetails.deviceSerialNumber}
                goToTripDetail={this.goToTripDetail}
                tripView={this.state.tripView}
                handleRefresh={this.handleRefresh}
                dateSelected={this.state.dateSelected}
                setDateSelected={this.setDateSelected}
                loadMapDirectionsFromTrips={this.loadMapDirectionsFromTrips}
                setZoomEvent={this.setZoomEvent}
                zoomEvent={this.state.zoomEvent}
                datePicked={this.state.datePicked}
                setDatePicked={this.setDatePicked}
                assetFeatures={this.state.assetDetails.assetFeatures}
                printPage={this.printPage}
                isTripsFetchError={this.props.isTripsFetchError}
                deviceModelCode={this.state.assetDetails.deviceModelCode}
                mapPageEventCallback={(cb) => {
                    this.mapPageEventCallback = cb;
                }}
                isMapRefreshed={this.props.mapRefreshLoader}
            />);
        }

        return (<AssetDetails
            assetsData={assetsDetails}
            setActiveTab={this.setActiveTab}
            isLoader={false}
            redirectTo={this.redirectTo}
            showBackButton
        />);
    };

    getDirectionsData = () => {
        const mapData = {
            directions: [],
            polyline: this.state.polylineData,
        };
        const filterTrip = this.mapDirections
            .filter(t => (t.result.filter(r => !isEmpty(r)).length === t.batchCount));
        filterTrip.forEach((r) => {
            mapData.directions.push(...r.result);
            mapData.polyline = mapData.polyline.filter(p => p.id !== r.tripId);
        });
        mapData.directions = mapData.directions.map(r => this.cloneGoogleDirectionRes(r));
        return mapData;
    }

    snapRoutesCallback = (flag) => {
        let { mapTrip } = this.state;
        if (isEmpty(mapTrip) && this.state.trips && this.state.trips.trips) {
            mapTrip = this.state.trips.trips;
        }
        let mapEvents = this.state.trips && this.state.trips.tripEvents &&
        this.state.trips.tripEvents.content ?
            this.state.trips.tripEvents.content : [];
        const selectedTripIds = [];
        const trips = mapTrip && Array.isArray(mapTrip) ? mapTrip.filter(trip => trip.type !== 'STOPPED') : [];
        trips.forEach(mT => selectedTripIds.push(mT.id));
        if (selectedTripIds.length > 0) {
            mapEvents = mapEvents.filter(event =>
                selectedTripIds.includes(event.tripId));
        }
        this.setState({ snapRoutes: flag }, () => {
            this.loadMapDirections(mapTrip, mapEvents);
        });
    }

    trackMapAnalytics = (...d) => {
        if (this.state.tripDetailIndex > -1) {
            let title = '';
            if (d.length && Object.keys(...d)[0] === 'snapRoutes') {
                title = 'ENABLE_SNAP_ROUTES';
            } else if (d.length && Object.keys(...d)[0] === 'mapTrafficLayer') {
                title = 'ENABLE_TRAFFIC';
            } else if (d.length && Object.keys(...d)[0] === 'mapAutoRefresh') {
                title = 'MAP_AUTO_REFRESH';
            }
            this.tripDetailsEventsTrack(title);
        } else if (this.mapPageEventCallback && typeof this.mapPageEventCallback === 'function') {
            this.mapPageEventCallback(...d);
        }
    }

    // From a given list of events, we identify Trip Start/END in the following order as follows,
    // IGN_ON/OFF -> If list has IGN_ON/OFF occurence
    // MOVE_START/STOP -> If list has MOVE_START/STOP occurence
    // When we have both, we take IGN_ON/OFF
    setTripStartEnd = (eventsParam : any) => {
        let events;
        if (eventsParam.content) {
            events = eventsParam.content.filter(event => event.eventTypeCode === IGNITION_ON ||
                event.eventTypeCode === IGNITION_OFF);
            if (events.length > 0) {
                this.tripStartEvent = IGNITION_ON;
                this.tripStopEvent = IGNITION_OFF;
            } else {
                events = eventsParam.content.filter(event =>
                    event.eventTypeCode === MOVEMENT_START ||
                    event.eventTypeCode === MOVEMENT_STOP);
                if (events.length > 0) {
                    this.tripStartEvent = MOVEMENT_START;
                    this.tripStopEvent = MOVEMENT_STOP;
                }
            }
        }
    }

    setInfoWindowEvent = (event: any) => {
        if (!isEmpty(event)) {
            this.setState({ infoWindowEvent: event });
        } else this.setState({ infoWindowEvent: {} });
    }

    tripIconClick = (event: any) => {
        if (event) {
            this.setZoomEvent(event.id);
            this.setInfoWindowEvent(event);
        } else {
            this.setZoomEvent();
            this.setInfoWindowEvent();
        }
    }

    getBoundary = (map) => {
        this.map = map;
    };

    // callback from map page when any bound change
    boundaryChanged = () => {
        if (this.map && Object.keys(this.map).length > 0) {
            const { trips } = this.state;
            if (trips && trips.trips) {
                setTimeout(() => {
                    // add small delay to avoid race condition and to get correct map bounds
                    this.getLandmarks(trips.trips);
                }, 1000);
            }
        }
    };

    getLandmarks = (trips) => {
        const { loadLandmarks } = this.state;

        if (!loadLandmarks) {
            return;
        }

        // if no trips then no need to draw landmarks
        if (!trips || trips.length === 0) {
            return;
        }

        // if map is not initialized yet return
        if (!this.map) {
            return;
        }

        // if no map bounds set yet return
        const bounds = this.map.getBounds();
        if (!bounds) {
            return;
        }

        const northEast = bounds.getNorthEast(); // LatLng of the north-east corner
        const southWest = bounds.getSouthWest();

        // on the initial map load, bounds are too large
        // check the difference and skip loading landmarks, is there a better way ?
        const latDiff = northEast.lat() - southWest.lat();
        const lngDiff = southWest.lng() - northEast.lng();
        if (Math.abs(latDiff) > 50 && Math.abs(lngDiff) > 50) {
            return;
        }

        const lat = bounds.getCenter().lat();
        const lng = bounds.getCenter().lng();

        // if not valid lat, lng, return
        if (!lat || !lng) {
            return;
        }

        this.setState({
            loadLandmarks: false,
        });

        this.getLandmarkData(lat, lng);
    };

    getLandmarkData = (lat, lng) => {
        getData(`${config.get('FLEET_VIEW_SERVICES_URL')}/landmarks/nearest?cb=${new Date().getTime()}&lat=${lat}&lng=${lng}&limit=200`)
            .then((response:any) => {
                this.setState({
                    landmarks: response.data ?
                        processLandmarkMarkers(response.data, this.props.history.push) : [],
                });
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.error(err);
            });
    };

    showHideLandmarks = (isHideLandmark) => {
        this.setState({ hideLandmarks: isHideLandmark });
    };

    render() {
        const {
            assetsDetails, isLoader, isTripLoader, isEventLoader,
        } = this.props;
        let markerOptions = {};
        const assetIcon = (assetsDetails && assetsDetails.assetDetails
            && assetsDetails.assetDetails.data && assetsDetails.assetDetails.data.attributes
            && assetsDetails.assetDetails.data.attributes.icon) ?
            assetsDetails.assetDetails.data.attributes.icon : assetsDetails.assetType;

        if (this.state.activeTab && this.state.activeTab === 'trips') {
            let hideIcon = false;
            let tripsCount = 0;
            if (this.state.trips && this.state.trips.trips) {
                tripsCount = this.state.trips.trips.filter(trip => trip.type === 'COMPLETED' || trip.type === 'INPROCESS').length;
            }
            if (this.state.dateSelected.toLowerCase() === 'last50' &&
                (this.state.selectedTripsCount === 1 ||
                    tripsCount === 1)) {
                hideIcon = true;
            }
            if (!this.props.isTripLoader && !this.props.isEventLoader) {
                let mapTripData = {
                    polyline: [],
                    directions: [],
                };
                let type = 'batch_directions';
                if (!this.state.snapRoutes || this.state.snapRoutes === 'false') {
                    mapTripData.polyline = this.state.polylineData;
                    type = 'polyline';
                } else {
                    mapTripData = this.getDirectionsData();
                }
                markerOptions = {
                    shape: {
                        type,
                        data: mapTripData.polyline,
                    },
                    directions: mapTripData.directions,
                    events: this.state.mapEvents,
                    zoomEvent: this.state.zoomEvent,
                    startPoint: this.state.mapStartPoint,
                    assetIcon,
                    hideIcon,
                    tripStartEvent: this.tripStartEvent,
                    tripStopEvent: this.tripStopEvent,
                    landmarks: (!this.state.hideLandmarks && this.state.landmarks)
                        ? this.state.landmarks : [],
                };
            }
        } else {
            markerOptions = {
                shape: {
                    type: 'trip',
                    location: this.getIcons(),
                    tripStartEvent: this.tripStartEvent,
                    tripStopEvent: this.tripStopEvent,
                },
            };
        }

        if (getParamValue(this.props, 'activeTab') === 'trips') {
            this.state.activeTab = 'trips';
        }

        const coordinates = {
            lat: assetsDetails ? Number(assetsDetails.lat) : 0,
            lng: assetsDetails ? Number(assetsDetails.lng) : 0,
        };

        const options = {};
        if (this.zoomLevel) options.zoom = this.zoomLevel;
        if (this.mapCenter) {
            coordinates.lat = this.mapCenter.lat();
            coordinates.lng = this.mapCenter.lng();
        }
        let adjustMapWidth = 0;
        if (this.state.isRightDrawerOpen) {
            if (this.state.activeTab && this.state.activeTab) {
                adjustMapWidth = (this.state.drawerWidth - 30);
            } else {
                adjustMapWidth = (defaultDrawerWidth - 30);
            }
        }

        if (this.props.match.params.isPrint === 'print') {
            return (
                <PrintEventsList
                    coordinates
                    markerOptions={markerOptions}
                    zoomEvent={this.state.zoomEvent}
                    events={this.state.trips && this.state.trips.tripEvents
                    && this.state.trips.tripEvents.content ?
                        this.state.trips.tripEvents.content : []}
                    options={options}
                    isLoader={isTripLoader}
                    assetFeatures={this.state.assetDetails ?
                        this.state.assetDetails.assetFeatures :
                        {}
                    }
                    deviceModelCode={this.state.assetDetails ?
                        this.state.assetDetails.deviceModelCode :
                        {}
                    }
                />
            );
        }
        return (
            <MiniDrawer redirectTo={this.props.history.push}>
                {isLoader ? <AppLoader type="fullScreen" /> : ''}
                <div key="asset-map-container" className={styles.mapWrapper} style={{ width: `calc(100% - ${adjustMapWidth}px)` }}>
                    <MapsWrapper
                        key="asset-map"
                        refreshData={this.refreshData}
                        coordinates={coordinates}
                        markers={markerOptions}
                        snapRoutesCallback={this.state.activeTab && this.state.activeTab === 'trips' ? this.snapRoutesCallback : null}
                        trackAnalytics={(this.trackMapAnalytics && typeof this.trackMapAnalytics === 'function') ? this.trackMapAnalytics : null}
                        tripIconClick={this.tripIconClick}
                        tripInfoBox={this.state.infoWindowEvent}
                        getBoundaries={(boundary) => { this.getBoundary(boundary); }}
                        boundaryChanged={() => this.boundaryChanged()}
                        hideLandmark={hideLM => this.showHideLandmarks(hideLM)}
                        {...options}
                    />
                </div>
                <div className={styles.container} >
                    <RightDrawer
                        showDrawer
                        drawerWidth={this.state.activeTab && this.state.activeTab === 'trips' ? this.state.drawerWidth : defaultDrawerWidth}
                        toggleDrawer={this.toggleRightDrawer}
                    >
                        {this.renderRightDrawer(assetsDetails, isTripLoader, isEventLoader)}
                    </RightDrawer>
                </div>
            </MiniDrawer>
        );
    }
}

const mapStateToProps = state => state.assets;

export default reduxConnect(Assets, actions, mapStateToProps);
